import { React, useState } from "react";
import img from "../images/services.jpg";
import Back from "../common/Back";
import "../home/featured/Featured.css";
import "./services.css";
import Img1 from "../images/carloan.jpg";
import Img2 from "../images/finance.jpg";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import Car from "../images/cr.png";
// import Tire from "../images/tr.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  marginTop: 10,
  p: 4,
};
const Services = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <section className="services mb location ">
        <Back name="Services" title="Services -All Services" cover={img} />

        <div className="content grid3 mtop">
          <div onClick={handleOpen} className="box hovereff">
            <img className="imgselection" src={Img1} alt="" />
            <div className="overlay">
              <h5>Apply For New Car Loan</h5>
            </div>
          </div>

          <Link to="/refinancemycar">
            <div className="box hovereff">
              <img className="imgselection" src={Img2} alt="" />
              <div className="overlay">
                <h5>Refinance Current Car</h5>
              </div>
            </div>
          </Link>
        </div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* <b>
                A $49.95 fee will be applied to process Pre-qualification
                application.
              </b> */}
              <br />
              <br /> The processing will give you access to your consultation
              about your credit report and eligibility for auto loan type.
              Application fees are used to cover screening costs incurred to
              process applications properly.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 20,
                marginTop: 30,
                overflowY: "scroll",
                flexWrap: "wrap",
              }}
            >
              <Link to="/qualifyform">
                <button className="ready_to_pay">Continue</button>
              </Link>
              <button className="cancel_btn_st" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default Services;
