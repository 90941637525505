import React from "react";
import Img1 from "../../images/blog1.jpeg";
import Img2 from "../../images/blog2.jpeg";
import Img3 from "../../images/blog3.jpeg";
import "./recent.css";
import { Link } from "react-router-dom";
const RecentCard = () => {
  return (
    <>
      <div className=" st_blog_main mtop">
        <div className="box inner_blog shadow">
          <div className="img">
            <Link to="/blog1">
              <img className="inner_img_blog" src={Img1} alt="" />
            </Link>
          </div>
          <div className="text text_blog">
            <h4>
              Ridiculous Inflation Is In this article To Stay (What This Means
              Regarding Car Prices)
            </h4>
          </div>
          <p className="text text_blog height_blog">
            Probably the most asked question in today’s market is – “WHEN are
            car prices going to go back to NORMAL? ” The particular answer, of
            course, is NEVER. To explain why I’m heading to go over two
          </p>
          <div className="text text_blog flex">
            <Link to="/blog1">
              <span style={{ color: "rgb(211, 177, 82)", cursor: "pointer" }}>
                Read More....
              </span>
            </Link>
          </div>
        </div>
        {/* 2nd blog */}
        <div className="box inner_blog shadow">
          <div className="img">
            <Link to="/blog2">
              <img className="inner_img_blog" src={Img2} alt="" />
            </Link>
          </div>
          <div className="text text_blog" style={{ height: 120 }}>
            <h4>What You Should Know About Dealer Finance</h4>
          </div>
          <p className="text text_blog ">
            What makes Car Prices Shooting upwards in the Usa States of The
            united states? Fresh and used automobile prices in typically the USA
            are attaining…
          </p>
          <div className="text text_blog flex">
            <Link to="/blog2">
              <span style={{ color: "rgb(211, 177, 82)", cursor: "pointer" }}>
                Read More....
              </span>
            </Link>
          </div>
        </div>
        {/* 3rd blog */}
        <div className="box inner_blog shadow">
          <div className="img">
            <Link to="/blog3">
              <img className="inner_img_blog" src={Img3} alt="" />
            </Link>
          </div>
          <div className="text text_blog">
            <h4>Why is APR so much higher than interest rate?</h4>
          </div>
          <p className="text text_blog">
            New Car Prices Are Up to $7,200 Over Last Year: Here’s Why There are
            four factors that are driving up the prices of new cars,…
          </p>
          <div className="text text_blog flex">
            <Link to="/blog3">
              <span style={{ color: "rgb(211, 177, 82)", cursor: "pointer" }}>
                Read More....
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentCard;
