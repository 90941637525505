import { React, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img2 from "../../images/pixel.jpeg";
// import Img3 from "../../images/car2.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import "./corousel.css";
const settings = {
  Infinite: true,
  speed: 100,
  slidesshow: 1,
  slidesToscroll: 1,
  autoplay: true,
  adaptiveHeight: true,
};
const SimpleSlider = () => {
  const SubmitConsulting = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_w09bx04",
        "template_yukbjot",
        e.target,
        "AL4ZgOott-0hvog1g"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div className="custom_css">
        <ToastContainer />
        {/* <h1 style={{ color: "white" }}>Get Moving,Save Big.</h1> */}
        <p style={{ color: "white", width: "60%" }}>
          {/* <span style={{ fontSize: 40, marginLeft: -30 }}>“</span>
          <span style={{ fontSize: 25 }}>Stop!</span> &nbsp;Don’t go to a spend
          all day at a Car Dealership and waste your time! Don’t over-spend on a
          Car and DON’T sign that contract! Let us at,&nbsp;&nbsp; */}
          <span style={{ fontSize: 25 }}>
            Consulting will give you a second opinion!
            <br />
          </span>
          <span style={{ fontSize: 35 }}>,,</span>
          Choose&nbsp;
          <span
            style={{
              fontSize: 30,
              color: "rgb(211, 177, 82)",
              fontFamily: "revert",
            }}
          >
            JTM&nbsp;
          </span>
          car financing service for competitive rates and personalized options
          that fit your budget and lifestyle.
          <span style={{ fontSize: 35 }}>,,</span>
        </p>
      </div>
      <form className="consulting_main" onSubmit={SubmitConsulting}>
        <div className="consult_child">
          <h3 className="font_st_free">Free Consulting</h3>
          <div>
            <input
              type="text"
              placeholder="Enter Name"
              className="input_consult"
              name="name"
            ></input>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              className="input_consult"
              name="user_email"
            ></input>
          </div>
          <div>
            <input
              type="number"
              placeholder="Phone Number"
              className="input_consult"
              name="phone"
            ></input>
          </div>
          <button type="submit" className="submit__consult">
            Submit
          </button>
        </div>
      </form>
      <div className="main_corousel">
        <Slider {...settings}>
          <div>
            <img className="image_corousel" src={Img2} alt="Image1"></img>
          </div>
        </Slider>
      </div>
    </div>
  );
};
export default SimpleSlider;
