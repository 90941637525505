import { React, useState, useEffect } from "react";
import Back from "../common/Back";
import img from "../images/carloan.jpg";
import "./refinance.css";
import axios from "axios";
const RefinanceMyCar = () => {
  // Form variabels-------------------------------------------------------------------
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [SocialSecurityno, setSocialSecurityno] = useState(null);
  const [Datebirth, setDatebirth] = useState("");
  const [Address, setAddress] = useState("");
  const [Aptno, setAptno] = useState(null);
  const [PoBox, setPoBox] = useState("");
  const [Ruralroute, setRuralroute] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [zip, setZip] = useState(null);
  const [timemonth, setTimemonth] = useState(null);
  const [PreviousAddress, setPreviousAddress] = useState("");
  const [PreviousAptno, setPreviousAptno] = useState(null);
  const [PreviousPobox, setPreviousPobox] = useState("");
  const [PreviousRuralroute, setPreviousRuralroute] = useState("");
  const [PreviousCity, setPreviousCity] = useState("");
  const [PreviousState, setPreviousState] = useState("");
  const [Previouszip, setPreviouszip] = useState(null);
  const [Previousyears, setPreviousyears] = useState(null);
  const [Previousmonth, setPreviousmonths] = useState(null);
  const [CellPhone, setCellPhone] = useState(null);
  const [ResidentialStatus, setResidentialStatus] = useState("");
  const [HomePhone, setHomePhone] = useState(null);
  const [Rent, setRent] = useState(null);
  const [Email, setEmail] = useState("");
  const [Salary, setSalary] = useState(null);
  const [Makecar, setMakecar] = useState("");
  const [Model, setModel] = useState("");
  const [Vin, setVin] = useState("");
  const [Miles, setMiles] = useState("");
  const [Years, setYears] = useState("");
  const [LeaseLoan, setLeaseLoan] = useState("");
  const [BankLease, setBankLease] = useState("");
  const [PayOffAmount, setPayOffAmount] = useState(null);
  const [CurrentInterest, setCurrentInterest] = useState("");
  const [OtherIncome, setOtherIncome] = useState(null);
  const [SourceOtherIncome, setSourceOtherIncome] = useState("");
  const [showtrading, setShowTrading] = useState(false);
  const [showalert, setShowAlert] = useState(false);
  // Form variabels End-------------------------------------------------------------------

  // Previous time variables Check by years----------------------------------------------------------
  const [PreviousTime, setPreviousTime] = useState(2);
  const [showPreviousTime, setshowPreviousTime] = useState(false);
  const CheckPreviousTime = () => {
    if (PreviousTime < 2) {
      setshowPreviousTime(true);
    }
    if (PreviousTime >= 2) {
      setshowPreviousTime(false);
    }
  };
  // Previous time variables End-------------------------------------------------------
  useEffect(() => {
    CheckPreviousTime();
  });
  const Tradinghandle = (e) => {
    if (e.target.value === "no") {
      setShowTrading(false);
    }
    if (e.target.value === "yes") {
      setShowTrading(true);
    }
  };
  // Refinance Api -------------------------------------------------------------------------

  const RefinanceHandel = () => {
    var data = JSON.stringify({
      first_name: Firstname,
      middle_initial: "",
      last_name: Lastname,
      social_security_number: SocialSecurityno,
      birth_date: Datebirth,
      address: Address,
      apt_suit: Aptno,
      po_box: PoBox,
      rural_route: Ruralroute,
      city: City,
      state: State,
      zip: zip,
      home_phone: HomePhone,
      cell_phone: CellPhone,
      residential_status: ResidentialStatus,
      time_at_adress: PreviousTime + "years" + timemonth + "months",
      rent_mtg_pmt: Rent,
      email: Email,
      drivers_license_no: "",
      drivers_license_state: "",
      time_at_previous_address:
        Previousyears + "years" + Previousmonth + "months",
      previous_address: PreviousAddress,
      previous_apt_suit: PreviousAptno,
      previous_po_box: PreviousPobox,
      previous_rural_route: PreviousRuralroute,
      previous_city: PreviousCity,
      previous_state: PreviousState,
      previous_zip: Previouszip,
      employer_name: "",
      employment_type: "",
      salary: Salary,
      salary_type: "",
      occupation: "",
      employment_length: "",
      work_phone_number: "",
      previous_employer_name: "",
      previous_employment_type: "",
      previous_salary: "",
      previous_salary_type: "",
      previous_occupation: "",
      previous_employment_length: "",
      previous_work_phone_number: "",
      other_monthly_income: OtherIncome,
      source_other_income: SourceOtherIncome,
      makeOfSellingCar: Makecar,
      modelOfSellingCar: Model,
      vinNum: Vin,
      miles: Miles,
      years: Years,
      currentlyLeaseOrSimpleTermLoan: LeaseLoan,
      bankOfCurrentLoanOrLeaseWith: BankLease,
      payOffAmount: PayOffAmount,
      currentInterestRate: CurrentInterest,
    });

    var config = {
      method: "post",
      url: "https://mailer-service-dot-fund-leads-dev.et.r.appspot.com/finance/refinance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setTimeout(() => {
          setShowAlert(true);
        }, 3000);
      })
      .catch(function (error) {
        
        console.log(error);
      });
  };
  return (
    <>
      <section>
        <Back name="Refinance" title="Refinance -Your Car" cover={img} />
        <div style={{ marginTop: 25 }}>
          <p className="head_loan">
            You are on the way to lowering your downpayment and lowering your
            interest rate...Get out of that high-interest-rate loan and save
            money — refinance your auto-loan now. Our speedy loan process will
            keep you in your vehicle at a payment that you can afford!
          </p>
        </div>
        <div className="container recent main_container_form">
          <div className="carloan_form">
            <h1 className="fill_p">Please Fill the Form</h1>
            <hr
              style={{
                backgroundColor: "rgb(211, 177, 82)",
                border: "1px solid rgb(211, 177, 82)",
              }}
            />
            {/* Row 1------------------------------------------ */}
            <div className="input1_parent">
              <div>
                <label className="label_1">First Name :</label>
                <br />
                <input
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                  placeholder="First Name"
                  className="input1_child"
                ></input>
              </div>
              <div>
                <label className="label_1">Last Name :</label>
                <br />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="input1_child"
                  onChange={(e) => setLastname(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 2----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Social Security Number :</label>
                <br />
                <input
                  type="number"
                  placeholder="Social Security Number"
                  className="input1_child"
                  onChange={(e) => setSocialSecurityno(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Birth Date :</label>
                <br />
                <input
                  type="date"
                  placeholder="Birth Date"
                  className="input1_child"
                  onChange={(e) => setDatebirth(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 3----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Address :</label>
                <br />
                <input
                  type="text"
                  placeholder="Address"
                  className="input1_child"
                  onChange={(e) => setAddress(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Apt# / Suite#:</label>
                <br />
                <input
                  type="number"
                  placeholder="Enter Number Here"
                  className="input1_child"
                  onChange={(e) => setAptno(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 4----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">P.O Box :</label>
                <br />
                <input
                  type="text"
                  placeholder="P.O Box"
                  className="input1_child"
                  onChange={(e) => setPoBox(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Rural Route</label>
                <br />
                <input
                  type="text"
                  placeholder="Rural Route"
                  className="input1_child"
                  onChange={(e) => setRuralroute(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 5----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">City :</label>
                <br />
                <input
                  type="text"
                  placeholder="City"
                  className="input1_child"
                  onChange={(e) => setCity(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">State :</label>
                <br />
                <input
                  type="text"
                  placeholder="State"
                  className="input1_child"
                  onChange={(e) => setState(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 6----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Zip :</label>
                <br />
                <input
                  type="number"
                  placeholder="Zip"
                  className="input1_child"
                  onChange={(e) => setZip(e.target.value)}
                ></input>
              </div>
              <div className="time_main_st">
                <label className="label_1">Time at Address :</label>
                <br />
                <input
                  type="number"
                  className="time_st"
                  // --------------------
                  // --------------------
                  // --------------------
                  // --------------------
                  // --------------------
                  // --------------------
                  onChange={(e) => setPreviousTime(e.target.value)}
                ></input>
                Yrs.
                <input
                  type="number"
                  className="time_st"
                  onChange={(e) => setTimemonth(e.target.value)}
                ></input>
                Mos.
              </div>
            </div>
            {/* Previous Address-------------------------- */}
            {!showPreviousTime ? (
              ""
            ) : (
              <div>
                {" "}
                {/* Row 1----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">Previous Full Address :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Address"
                      className="input1_child"
                      onChange={(e) => setPreviousAddress(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">Previous Apt# / Suite#:</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Enter Number Here"
                      className="input1_child"
                      onChange={(e) => setPreviousAptno(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 2----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">P.O Box :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="P.O Box"
                      className="input1_child"
                      onChange={(e) => setPreviousPobox(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">Rural Route</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Rural Route"
                      className="input1_child"
                      onChange={(e) => setPreviousRuralroute(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 3----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">City :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="City"
                      className="input1_child"
                      onChange={(e) => setPreviousCity(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">State :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="State"
                      className="input1_child"
                      onChange={(e) => setPreviousState(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 4----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">Zip :</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Zip"
                      className="input1_child"
                      onChange={(e) => setPreviouszip(e.target.value)}
                    ></input>
                  </div>
                  <div className="time_main_st">
                    <label className="label_1">
                      Time at Previous Address :
                    </label>
                    <br />
                    <input
                      type="number"
                      className="time_st"
                      onChange={(e) => setPreviousyears(e.target.value)}
                    ></input>
                    Yrs.
                    <input
                      type="number"
                      className="time_st"
                      onChange={(e) => setPreviousmonths(e.target.value)}
                    ></input>
                    Mos.
                  </div>
                </div>
              </div>
            )}
            {/* Row 7----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Cell Phone :</label>
                <br />
                <input
                  type="number"
                  placeholder="Cell Phone"
                  className="input1_child"
                  onChange={(e) => setCellPhone(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Residential Status(Current) :</label>
                <br />
                <div className="radio_st">
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Homeowner"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Homeowner&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Rent"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Rent&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Family"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Family&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Other"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Other&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
            {/* Row 6----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Home Phone :</label>
                <br />
                <input
                  type="tel"
                  placeholder="Home Phone"
                  className="input1_child"
                  onChange={(e) => setHomePhone(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Rent/Mtg. Pmt $ :</label>
                <br />
                <input
                  type="number"
                  placeholder="Rent/Mtg $"
                  className="input1_child"
                  onChange={(e) => setRent(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 7----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">E-Mail Address :</label>
                <br />
                <input
                  type="email"
                  placeholder="E-Mail "
                  className="input1_child"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Salary $ (Monthly) :</label>
                <br />
                <input
                  type="number"
                  placeholder="Salary in $"
                  className="input1_child"
                  onChange={(e) => setSalary(e.target.value)}
                ></input>
              </div>
            </div>
            {/* trading setting dropdown */}
            <div style={{ marginTop: 20 }}></div>
            <label style={{ marginLeft: 23 }} className="label_1">
              Are You Trading ? :
            </label>
            <br />
            <select onChange={Tradinghandle} name="" id="select_trading">
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
            {/* Trading container----------------------------------- */}
            {showtrading === true ? (
              <div className="trading_main">
                {/* Row 8----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">
                      Make Of Car You Are Trading :
                    </label>
                    <br />
                    <input
                      type="6"
                      placeholder="Car You Are Trading"
                      className="input1_child"
                      onChange={(e) => setMakecar(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">MODEL :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="MODEL"
                      className="input1_child"
                      onChange={(e) => setModel(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 9----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">VIN# :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Vin#"
                      className="input1_child"
                      onChange={(e) => setVin(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">Miles :</label>
                    <br />
                    <input
                      type="Miles"
                      placeholder="How many Miles?"
                      className="input1_child"
                      onChange={(e) => setMiles(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 10----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">Years :</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Years"
                      className="input1_child"
                      onChange={(e) => setYears(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">
                      Do You Currently Lease Or Simple Term Loan? :
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Lease Or Simple Term Loan?"
                      className="input1_child"
                      onChange={(e) => setLeaseLoan(e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Row 11----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">
                      What Bank Is The Current Loan Or Lease With? :
                    </label>
                    <br />
                    <input
                      type="number"
                      placeholder="Bank Name"
                      className="input1_child"
                      onChange={(e) => setBankLease(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    <label className="label_1">
                      How Much Is The Pay Off Amount? :
                    </label>
                    <br />
                    <input
                      type="number"
                      placeholder="Pay Off Amount?"
                      className="input1_child"
                      onChange={(e) => setPayOffAmount(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Previous Employer history---------------- */}
            {/* Row 1----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">
                  What Is Their Current Interest Rate % :
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Current Interest Rate %"
                  className="input1_child"
                  onChange={(e) => setCurrentInterest(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Source of other Income :</label>
                <br />
                <input
                  type="text"
                  placeholder="(Optional)"
                  className="input1_child"
                  onChange={(e) => setSourceOtherIncome(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 12----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Other Income (if any) :</label>
                <br />
                <input
                  type="number"
                  placeholder="(Optional)"
                  className="input1_child"
                  onChange={(e) => setOtherIncome(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Previous Employer history End---------------- */}
            {/* Row 12----------------------------------- */}
            <div className="input1_parent"></div>
            <hr
              style={{
                backgroundColor: "rgb(211, 177, 82)",
                border: "1px solid rgb(211, 177, 82)",
              }}
            />
            <div className="submit_container">
              <button onClick={RefinanceHandel} className="submit_child_st">
                Submit Form
              </button>
            </div>
            {showalert === true ? (
              <div class="alert flex justify-content-center alert-success" role="alert">
                Details Added successfully
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default RefinanceMyCar;
