import React from "react";
import Back from "../common/Back";
import img from "../images/pricing.jpg";
import "./termofuse.css";
const TermsOfUse = () => {
  return (
    <>
      <section className="pricing mb">
        <Back name="JTM Consulting" title="Terms Of Use" cover={img} />
        <div className="main_container_terms">
          <span>updated 10/28/2022</span>
          <p style={{ marginTop: 50 }}>
            We offer this website, loan application, and our services, subject
            to the following terms of use (“Agreement”). Please read this
            Agreement carefully before using this website or submitting a loan
            application. By using this website, you accept the terms of use set
            forth in this Agreement. If you do not agree to the terms of use set
            forth in this Agreement, you must not use this website or submit a
            loan application.
          </p>
          <p style={{ marginTop: 50 }}>
            As used in this Agreement, the terms “we” and “us” mean Matutech
            LLC, Inc. and the companies in which Matutech LLC., Inc. directly or
            indirectly owns a majority interest, commonly called “subsidiaries,”
            including but not limited to, Matutech LLC, Inc. d/b/a Matutech LLC,
            RateGenius Loan Services, Inc. d/b/a RateGenius, Innovative Funding
            Services Corporation d/b/a Tresl. We operate a technology driven
            loan and product matching system whereby consumers are able to apply
            to finance, purchase or refinance their vehicles and be considered
            by multiple lenders who may offer credit and options for ancillary
            products related to the loan or vehicle. We also provide origination
            fulfillment services for approved loan offers including, but not
            limited to, communication of the offer, documentation collection and
            preparation, and lien perfection. In most cases, we are not the
            lender or affiliated with the lender, however, we may offer loans in
            certain states through a licensed subsidiary. On a limited scale, we
            also provide claims administrations for certain loan products. TERMS
            OF USE; ELECTRONIC DOCUMENT POLICY; AND PRIVACY POLICY (READ AND
            SCROLL DOWN):
          </p>
          <h3 style={{ marginTop: 30, fontWeight: "normal", padding: 30 }}>
            1. TERMS OF USE:
          </h3>
          <p>
            By submitting an application,
            <span style={{ fontWeight: "bold" }}>
              you represent that you have read, understand, and agree to the
              following Terms of Use
            </span>{" "}
            . If a joint application is filed, these Terms of Use apply to each
            of the applicants equally:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              You represent that the information that you provide in the
              application process is true and complete, and that you are over
              the age of 18 and have the power and authority to enter into and
              perform your obligations under any credit arrangement that may be
              offered;
            </li>
            <li>
              You acknowledge that you may withdraw your application at any time
              prior to executing any loan agreement;
            </li>
            <li>
              You authorize us to pull your credit report for the purpose of
              evaluating your credit options for financing your loan through our
              network of lenders, lending partners, and third-parties (“Lender
              Network”);
            </li>
            <li>
              You consent to the sharing of the information you have provided in
              the application and any credit reports obtained by us with one or
              more parties in the Lender Network;
            </li>
            <li>
              You authorize any party in the Lender Network, to which we have
              submitted your application for credit, to pull your credit report
              in order to evaluate your application;
            </li>
            <li>
              You acknowledge that we and any party in the Lender Network may
              retain your loan application information in order to comply with
              certain federal and state laws and regulations;
            </li>
            <li>You acknowledge that you have read our Privacy Policy;</li>
            <li>
              You acknowledge that we do not guarantee that a lender will make
              you an offer to finance your loan;
            </li>
            <li>
              You acknowledge that we do not guarantee that a lender will
              finance your loan at specific terms and conditions, including a
              specific interest rate;
            </li>
            <li>
              You acknowledge that we are not responsible for any errors or
              delays related to your application caused by incorrect information
              provided by you or other technical problems beyond its reasonable
              control;
            </li>
            <li>
              You may consent to receive notices electronically and agree to
              receive such notices at our website. These notices may include
              privacy notices and information required by the Equal Credit
              Opportunity Act;
            </li>
            <li>
              You acknowledge that the services offered by us are available only
              to residents of, or secured by collateral located in, those states
              where we are licensed (if licensing is required) and those states
              where any party in our Lender Network are licensed or authorized
              to make such loans;
            </li>
            <li>
              You acknowledge that under no circumstances will we be liable to
              you or to any other person for any indirect, incidental,
              consequential, or punitive damages arising out of or relating to
              this agreement; and
            </li>
            <li>
              You acknowledge that this agreement shall be subject to the laws
              of the State of Texas, excluding its conflict of law principles.
            </li>
          </ol>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              CUSTOMER COMMUNICATIONS:{" "}
            </span>
            By accepting these Terms of Use, you expressly consent to be
            contacted by us, our agents, representatives, affiliates, or any
            party in the Lender Network for any and all purposes related to your
            application and any related loan, at any telephone number, or
            physical or electronic address you provide or at which you may be
            reached. You agree that we, our agents, representatives, affiliates,
            or any party in the Lender Network may contact you in any way,
            including SMS messages (including text messages), calls using
            prerecorded messages or artificial voice, and calls and messages
            delivered using auto telephone dialing system or an automatic
            texting system. Automated messages may be played when the telephone
            is answered, whether by you or someone else. In the event that an
            agent or representative calls, he or she may also leave a message on
            your answering machine, voice mail, or send one via text. You
            consent to having all calls between you and us recorded for quality
            assurance. You consent to receive SMS messages (including text
            messages), calls and messages (including prerecorded and artificial
            voice and autodialed) from us, our agents, representatives,
            affiliates or party in the Lender Network at the specific number(s)
            you have provided to us, or numbers we can reasonably associate with
            your account (through skip trace, caller ID capture or other means),
            with information or questions about your application, loan and/or
            account. You certify, warrant and represent that the telephone
            numbers that you have provided to us are your numbers and not
            someone else’s. You represent that you are permitted to receive
            calls at each of the telephone numbers you have provided to us. You
            agree to alert us whenever you stop using a particular telephone
            number. You also understand that your cellular or mobile telephone
            provider will charge you according to the type of plan you carry.
          </p>

          <h3 style={{ fontWeight: "bold", marginTop: 40 }}>
            Notice to California Residents:
          </h3>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to California Residents:
            </span>
            If married, you may apply for credit separately as an individual.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to Maine Residents:
            </span>
            You must have physical damage insurance covering loss or damage 1.0
            the vehicle for the term of any loan. You may buy this insurance
            from anyone you choose. You do not have to buy it from someone
            affiliated with the lender. Your choice of insurance will not affect
            the credit approval process unless the insurance does not satisfy
            the contract requirements or the insurance company does not satisfy
            the reasonable standards of the dealer or an assignee of the
            contract.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to New Hampshire Residents:
            </span>
            If this is an application for a balloon contract, you are entitled
            to receive, upon request, a written estimate of the monthly payment
            amount that would be required to refinance the balloon payment at
            the time such payment is due based on your current refinancing
            programs. A balloon contract is an installment sale contract with a
            final scheduled payment that is at least twice the amount of one of
            the earlier scheduled equal periodic installment payments.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to New York Residents:
            </span>
            Consumer reports may be requested in connection with this
            application. Upon request, you will be informed whether or not a
            consumer report was requested and, if it was, of the name and
            address of the consumer reporting agency that furnished the report.
            Additional consumer reports may be requested with respect to any
            extension or renewal of this obligation.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to Ohio Residents:
            </span>
            The Ohio laws against discrimination require that all creditors make
            credit equally available to all creditworthy customers, and that
            credit reporting agencies maintain separate credit histories on each
            individual upon request. The Ohio Civil Rights Commission
            administers compliance with this law.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to Rhode Island Residents:
            </span>
            Credit Reports may be obtained in connect ion with this application
            for credit.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Note to Vermont Residents:
            </span>
            You authorize us and any financial service provider with whom this
            application is shared, to obtain credit reports to evaluate your
            credit application. If your application is approved and credit is
            granted, you also authorize all parties granting credit, holding
            and/or servicing your account, to obtain additional credit reports
            in connection with your account, as permitted by law.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Notice to Wisconsin Residents:
            </span>
            The interest of the creditor will not be adversely affected by a
            provision of a marital property agreement, a unilateral statement
            under Wisconsin Statutes § 766.59 or a court decree under Wisconsin
            Statue § 766.70, unless you furnish a copy of such agreement,
            statement or decree to the creditor, or the creditor has actual
            knowledge of such provision before credit is granted.
          </p>
          <h3 style={{ marginTop: 30, fontWeight: "normal", padding: 30 }}>
            1. ELECTRONIC DOCUMENT POLICY: Please read this Electronic Records
            Disclosure and Agreement carefully and keep a copy for your records.
            DISCLOSURE REQUIRED BY FEDERAL LAW.
          </h3>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              ELECTRONIC RECORDS DISCLOSURE AND AGREEMENT:
            </span>
            Electronic Copy of Related Disclosures, Agreements, and
            Instructions. In order to speed up the loan application process,
            with your consent, we, our network of lenders, lending partners, and
            third-parties (“Lender Network”) will provide you with the following
            information electronically, rather than by postal mail or in person:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              A loan application and all related disclosures required by
              applicable federal and state law for the loan you have selected;
            </li>
            <li>
              Information and instructions about any additional services that
              you select during the application process
            </li>
          </ol>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Your Consent is Required.
            </span>
            You must consent to receiving the related legal disclosures,
            agreements, and instructions before they can be provided to you
            electronically. Your consent will only apply to the loan and related
            products you have selected and their related legal disclosures,
            agreements and instructions. You will not be consenting to receiving
            other electronic records or disclosures at this time.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Paper Copy of Disclosures, Agreements and Instructions.
            </span>
            If you do not want to receive the legal disclosures, agreements and
            the instructions electronically, you should exit this area of our
            website. If you consent to receive the disclosures, agreements and
            instructions electronically, you can also request a paper copy of
            the related legal disclosures, agreements and instructions.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Withdrawal of Consent for Electronic Delivery.
            </span>
            You may withdraw consent for electronic delivery of legal
            disclosures, agreements, instructions, and communications, but doing
            so will not affect the legal effectiveness, validity or
            enforceability of electronic records that were made available to you
            prior to the implementation of your withdrawal of consent for
            electronic delivery.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              System Requirements to Access the Information.
            </span>
            To receive an electronic copy of the legal disclosures, agreements,
            and instructions related to your loan and any related products, you
            must have the following equipment and software:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              You must have a personal computer or other access device that is
              capable of accessing the Internet (e.g., you must have a modem and
              available phone line, a cable Internet connection or some other
              means of access to the Internet, and you must have an active
              account with an Internet service provider).
            </li>
            <li>
              You must have an Internet web browser that is capable of
              supporting 128-bit SSL encrypted communications, which requires a
              minimum web browser version of either Microsoft® Internet Explorer
              version 6.0 or Netscape Navigator® version 4.73, and your system
              must have 128-bit SSL encryption software.
            </li>
            <li>
              You must have software that permits you to receive and access
              Portable Document Format or “PDF” files, such as Adobe Acrobat
              Reader® version 5.1 or higher, which is available for download at
              Adobe’s website.
            </li>
          </ol>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Agreements and Acknowledgments:
            </span>
            By submitting your application through our website, you represent
            that: (i) you have read, understand and agree to all of the
            provisions of this Electronic Records Disclosure and Agreement (the
            “Agreement”); and (ii) you represent that the Internet access
            device(s) you will use to receive this Agreement and related legal
            disclosures, agreements, instructions, communications and online
            statements meet(s) the system requirements to access information and
            to retain information as stated in the previous two sections.
          </p>
          <h3 style={{ marginTop: 30, fontWeight: "bold", padding: 30,fontSize:16 }}>
            1. PRIVACY POLICY:
          </h3>
        </div>
      </section>
    </>
  );
};

export default TermsOfUse;
