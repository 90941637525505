import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
const Thankyou = () => {
  return (
    <div>
      <section class="login-main-wrapper">
        <div class="main-container">
          <div class="login-process">
            <div class="login-main-container">
              <div class="thankyou-wrapper">
                <h1>
                  <img
                    src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png"
                    alt="thanks"
                    height="250px"
                    width="250px"
                    className="imgthank"
                  />
                </h1>
                <p>
                  for applying , Please check your Email for further process..{" "}
                </p>
                <Link to="/">Back to home</Link>
                <div class="clr"></div>
              </div>
              <div class="clr"></div>
            </div>
          </div>
          <div class="clr"></div>
        </div>
      </section>
    </div>
  );
};

export default Thankyou;
