import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testomi.css";
import Heading from "../../common/Heading";
export default class Testominals extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 4000,
      // cssEase: "linear",
      pauseOnHover: false,
    };
    return (
      <div>
        <Heading
          title="Why Choose Us?"
          subtitle="We’ve been in the sales and finance industry for over 25 years.  We will share all the trade secrets they don’t want you to know.  At JTM Consulting, you win!"
        />
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <Slider {...settings}>
            <div>
              <figure class="snip1533">
                <figcaption>
                  <blockquote>
                    <p>
                      “JTM Consulting offers amazing service, very easy to going
                      and understand what I was looking for, thanks again for
                      your help and I will be using your service in the future
                      again."
                    </p>
                  </blockquote>
                  <h3>Jimmy Casas</h3>
                  <h4>Toyota dealer</h4>
                </figcaption>
              </figure>
            </div>
            <div>
              <figure class="snip1533">
                <figcaption>
                  <blockquote>
                    <p>
                      “JTM Consulting helped us get lower payments on our
                      existing car loan. They made it easy. I highly recommend
                      them.”
                    </p>
                  </blockquote>
                  <h3> Ronald Ramirez</h3>
                  <h4>Bentaly</h4>
                </figcaption>
              </figure>
            </div>
            <div>
              <figure class="snip1533">
                <figcaption>
                  <blockquote>
                    <p>
                      “They helped me locate the Porsche, negotiate a good price
                      and lease on my company name to save thousands of dollars
                      and time. They even arranged to deliver the Porsche to me.
                      I highly recommend JTM Consulting."
                    </p>
                  </blockquote>
                  <h3>Linkin Holad</h3>
                  <h4>Porsche Macan</h4>
                </figcaption>
              </figure>
            </div>
            <div>
              <figure class="snip1533">
                <figcaption>
                  <blockquote>
                    <p>
                      “JTM Consulting helped me find my brand new Porsche Macan.
                      I am too busy with business, and did not want to deal with
                      dealership games through the process. They helped me
                      locate the Porsche, negotiate a good price and lease on my
                      company name to save thousands of dollars and time. They
                      even arranged to deliver the Porsche to me. I highly
                      recommend JTM Consulting.”
                    </p>
                  </blockquote>
                  <h3>Reyna R. A</h3>
                  <h4>Porsche Macan</h4>
                </figcaption>
              </figure>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
