import React from "react";
import "./negotiate.css";
import { Link } from "react-router-dom";
const Negotiate = () => {
  return (
    <>
      <div className="consulting_main2">
        <div className="consult_child2">
          <h3 className="font_st_free2">Looking for Negotiating Expert?</h3>
          <div className="st_st_st">
            <p style={{ fontSize: 17 }}>
              Get quotes from the best banks around. Easy, fast, and hassle free
              interest rates and terms you can afford.
            </p>
            <Link to="/qualifyform">
              <button style={{ marginTop: 20 }} className="submit__consult">
                Book Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Negotiate;
