import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Logo from "../../images/jtmlogo.png";
const Header = () => {
  const MyFunction = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsivee";
    } else {
      x.className = "topnav";
    }
  };
  return (
    <>
      <div class="topnav" id="myTopnav">
        <Link to="/" class="">
          <img className="logoimgcss" src={Logo} alt="" />
        </Link>
        <div class="nav-links">
          <Link className="anchor2" to="/">
            Home
          </Link>
          <Link className="anchor" to="/services" onClick={MyFunction}>
            Services
          </Link>
          <Link className="anchor" to="/luxurycars" onClick={MyFunction}>
            Luxury
          </Link>
          <Link className="anchor" to="/warrantypurchase" onClick={MyFunction}>
            Warranty
          </Link>
          <Link className="anchor" to="/blog" onClick={MyFunction}>
            Blogs
          </Link>
          <Link className="anchor" to="/about" onClick={MyFunction}>
            About
          </Link>
          <Link to="/consulting">
            <li
              style={{
                color: "white",
                marginLeft: 20,
                backgroundColor: "rgb(171, 124, 45)",
                height: 40,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                fontSize: 15,
                fontWeight: 500,
                marginTop: 30,
              }}
              className=""
            >
              Free Consultation
            </li>
          </Link>
        </div>
        <a class="icon" onClick={MyFunction}>
          <i class="fa fa-bars"></i>
        </a>
      </div>
      {/* <div class="nav">
        <input type="checkbox" id="nav-check" />
        <div class="nav-header">
          <img className="logoimgcss" src={Logo} alt="" />
        </div>
        <div class="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div class="nav-links">
          <Link className="anchor" to="/">
            Home
          </Link>

          <Link className="anchor" to="/services">
            Services
          </Link>
          <Link className="anchor" to="/luxurycars">
            Luxury
          </Link>
          <Link className="anchor" to="/warrantypurchase">
            Warranty
          </Link>
          <Link className="anchor" to="/blog">
            Blogs
          </Link>
          <Link className="anchor" to="/about">
            About
          </Link>
          <Link to="/consulting">
            <li
              style={{
                color: "white",
                marginLeft: 20,
                backgroundColor: "rgb(171, 124, 45)",
                height: 40,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                fontSize: 15,
                fontWeight: 500,
              }}
              className=""
            >
              Free Consultation
            </li>
          </Link>
        </div>
      </div> */}
      {/* <header>
        <div className="container flex">
          <img className="logoimgcss" src={Logo} alt="" />
          <div className="logo"></div>
          <div className="nav">
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path}>{list.text}</Link>
                </li>
              ))}
              <Link to="/consulting">
                <li
                  style={{
                    color: "white",
                    marginLeft: 20,
                    backgroundColor: "rgb(171, 124, 45)",
                    height: 40,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    borderRadius: 10,
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  className=""
                >
                  Free Consultation
                </li>
              </Link>
            </ul>
          </div>
          <div className="toggle">
            <button className="btn_toggel" onClick={() => setNavList(!navList)}>
              {navList ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-bars"></i>
              )}
            </button>
          </div>
        </div>
      </header> */}
    </>
  );
};

export default Header;
