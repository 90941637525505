import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import SlidingCar from "../../images/jeep-background-removed.png";
import "./animation.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  marginTop: 10,
};
const ThirdComponent = () => {
  const myref = useRef();
  const [visible, setvisible] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    console.log(myref);
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setvisible(entry.isIntersecting);
    });
    observer.observe(myref.current);
  }, []);
  return (
    <Main className="margin_top">
      <Part1>
        <p>We value the time and quality for each of our clients</p>
        <h1>
          Incredible <br />
          <b>comfort</b>
        </h1>
      </Part1>
      <CarSlide ref={myref} visible={visible}>
        <button onClick={handleOpen} className="animate_btn_car">
          Get Approved
        </button>
        <img className="slide_car_st" src={SlidingCar} alt="slidingCar" />
      </CarSlide>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={style}>
          <Typography
            style={{
              color: "rgb(211, 177, 82)",
              fontWeight: "bold",
              marginBottom: 20,
              overflowY: "scroll",
              height: "auto",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Hurry Up! Don't Waste Your Time.
          </Typography>
          {/* <Typography className="display_car_animatio">
            <video width="100%" height="100%" autoPlay loop>
              <source
                width="100%"
                src="../../images/driving.mp4"
                type="video/mp4"
              />
            </video>
          </Typography> */}
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            A $48 fee will be applied to process Pre-qualification application.
            <br />
            <br /> The processing will give you access to your consultation
            about your credit report and eligibility for auto loan type.
            Application fees are used to cover screening costs incurred to
            process applications properly.
            <br />
            <br />
            Just Fill Out The Form To Apply For a Loan And Enjoy Your Dream
            Car..
          </Typography>
          <Typography></Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              marginTop: 30,
              overflowY: "scroll",
            }}
          >
            <Link to="/qualifyform">
              <button className="ready_to_pay">Yes i,m ready</button>
            </Link>
            <button className="cancel_btn_st" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </Main>
  );
};
export default ThirdComponent;
const Main = styled.div`
  position: relative;
  overflow: hidden;
`;

const Part1 = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.5em;
    max-width: 40ch;
    text-align: center;
  }
  h1 {
    font-size: 3.5vw;
    text-align: center;
    b {
      color: #d7b65d;
    }
    margin-bottom: 9em;
  }
  ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("/images/clouds.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
  @media (min-width: 0px) and (max-width: 768px) {
    height: 40vh;
  }
`;
const Animation = keyframes`
0%{
  left: 100%;
}
100%{
  left: 10%;
}
`;

const CarSlide = styled.div`
  position: absolute;
  top: 30%;
  width: 80%;
  left: 10%;
  animation: ${(props) => (props.visible ? Animation : "none")} 2s linear;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
