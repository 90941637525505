import React from "react";
// import Back from "../common/Back";
import Heading from "../common/Heading";
// import img from "../images/about.jpg";
import img1 from "../images/second-section-image-600x591 1.png";
import "./about.css";
import Awards from "../home/awards/Awards";
const About = () => {
  return (
    <>
      <section className="about">
        {/* <Back name="About Us" title="About Us - Who We Are?" cover={img} /> */}
        <div className="container flex mtop">
          <div className="left row">
            <Heading
              title="Our Agency Story"
              subtitle="Check out our company story and work process"
            />
            <h2 style={{ fontWeight: "normal" }}>
              We have over 25 years of industry experience.
            </h2>
            <p>
              Whether you’re purchasing a car for the first time or the
              thousandth time, we are here to help. Our competitive rates,
              refinancing, and loan protection help you get the most out of your
              money.Our Staff all have automobile Dealership and finance
              background! We know the ins-and-outs of the industry. Negotiate
              better, save time, with JTM Consulting.
            </p>

            {/* <button className="btn2">More About Us</button> */}
          </div>
          <div className="right row">
            <img className="img------st" src={img1} alt="" />
          </div>
        </div>
        <Awards />
      </section>
    </>
  );
};

export default About;
