import React, { Component } from "react";
import SignaturePad from "react-signature-canvas";
import "./carloan.css";
export default class SignaturePadClass extends Component {
  state = { trimmedDataURL: null };
  sigPad = {};
  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  render() {
    let { trimmedDataURL } = this.state;
    return (
      <div className="containerst">
        <div className="sigContainer">
          <SignaturePad
            canvasProps={{ className: "sigPadd" }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>
        <div>
          <button className="clearbtn" onClick={this.clear}>
            Clear Pad
          </button>
          <button className="buttonst" onClick={this.trim}>
            Save Signature
          </button>
        </div>
        <div>
          {trimmedDataURL ? (
            <img className="sigImage" src={trimmedDataURL} />
          ) : null}
        </div>
      </div>
    );
  }
}
