import { React, useState, useEffect } from "react";
import Back from "../common/Back";
import "../home/recent/recent.css";
import img from "../images/about.jpg";
import "./carloan.css";
import SignaturePadClass from "./signature";
// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};
const CarLoan = () => {
  // Form variabels-------------------------------------------------------------------
  const [transsuccess, settranssuccess] = useState(false);
  const [emptyerror, setemptyerror] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [transerror, settranserror] = useState(false);
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Middlename, setMiddlename] = useState("");
  const [SocialSecurityno, setSocialSecurityno] = useState("");
  const [Datebirth, setDatebirth] = useState("");
  const [Address, setAddress] = useState("");
  const [Aptno, setAptno] = useState("");
  const [PoBox, setPoBox] = useState("");
  const [Ruralroute, setRuralroute] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [zip, setZip] = useState("");
  const [timemonth, setTimemonth] = useState("");
  const [PreviousAddress, setPreviousAddress] = useState("");
  const [PreviousAptno, setPreviousAptno] = useState("");
  const [PreviousPobox, setPreviousPobox] = useState("");
  const [PreviousRuralroute, setPreviousRuralroute] = useState("");
  const [PreviousCity, setPreviousCity] = useState("");
  const [PreviousState, setPreviousState] = useState("");
  const [Previouszip, setPreviouszip] = useState("");
  const [Previousyears, setPreviousyears] = useState("");
  const [Previousmonth, setPreviousmonths] = useState("");
  const [CellPhone, setCellPhone] = useState("");
  const [ResidentialStatus, setResidentialStatus] = useState("");
  const [HomePhone, setHomePhone] = useState("");
  const [Rent, setRent] = useState("");
  const [Email, setEmail] = useState("");
  const [Licenseno, setLicenseno] = useState("");
  const [LicenseState, setLicenseState] = useState("");
  const [Employername, setEmployername] = useState("");
  const [Employertype, setEmployertype] = useState("");
  const [Salary, setSalary] = useState("");
  const [Salarytype, setSalarytype] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [Employmentyears, setEmploymentyears] = useState("");
  const [Employmentmonths, setEmploymentmonths] = useState("");
  const [WorkPhone, setWorkPhone] = useState("");
  const [PreviousEmployername, setPreviousEmployername] = useState("");
  const [PreviousEmployertype, setPreviousEmployertype] = useState("");
  const [PreviousOccupation, setPreviousOccupation] = useState("");
  const [PreviousEmploymentyears, setPreviousEmploymentyears] = useState("");
  const [PreviousEmploymentmonths, setPreviousEmploymentmonths] = useState("");
  const [PreviousWorkPhone, setPreviousWorkPhone] = useState("");
  const [OtherIncome, setOtherIncome] = useState("");
  const [SourceOtherIncome, setSourceOtherIncome] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  // Form variabels End-------------------------------------------------------------------

  // Previous time variables Check by years----------------------------------------------------------
  const [PreviousTime, setPreviousTime] = useState(2);
  const [showPreviousTime, setshowPreviousTime] = useState(false);
  const CheckPreviousTime = () => {
    if (PreviousTime < 2) {
      setshowPreviousTime(true);
    }
    if (PreviousTime >= 2) {
      setshowPreviousTime(false);
    }
  };
  // Previous time variables End-------------------------------------------------------
  useEffect(() => {
    CheckPreviousTime();
  });
  // ApI Carloan --------------------------------------------------------------------------
  const CraloanHandle = () => {
    if (
      Firstname.length === 0 ||
      Middlename.length === 0 ||
      Lastname.length === 0 ||
      SocialSecurityno.length === 0 ||
      Datebirth.length === 0 ||
      Address.length === 0 ||
      Aptno.length === 0 ||
      PoBox.length === 0 ||
      Ruralroute.length === 0 ||
      City.length === 0 ||
      State.length === 0 ||
      zip.length === 0 ||
      HomePhone.length === 0 ||
      CellPhone.length === 0 ||
      ResidentialStatus.length === 0 ||
      timemonth.length === 0 ||
      Rent.length === 0 ||
      Email.length === 0 ||
      Previousyears.length === 0 ||
      PreviousAddress.length === 0 ||
      PreviousAptno.length === 0 ||
      PreviousPobox.length === 0 ||
      PreviousRuralroute.length === 0 ||
      PreviousCity.length === 0 ||
      PreviousState.length === 0 ||
      Previouszip.length === 0 ||
      Employername.length === 0 ||
      Employertype.length === 0 ||
      Salary.length === 0 ||
      Salarytype.length === 0 ||
      Occupation.length === 0 ||
      Employmentyears.length === 0 ||
      WorkPhone.length === 0 ||
      PreviousEmployername.length === 0 ||
      PreviousEmployertype.length === 0 ||
      PreviousOccupation.length === 0 ||
      PreviousEmploymentyears.length === 0 ||
      PreviousWorkPhone.length === 0
    ) {
      setEmpty(true);
    }
    if (Firstname) {
      var data = JSON.stringify({
        first_name: Firstname,
        middle_initial: Middlename,
        last_name: Lastname,
        social_security_number: SocialSecurityno,
        birth_date: Datebirth,
        address: Address,
        apt_suit: Aptno,
        po_box: PoBox,
        rural_route: Ruralroute,
        city: City,
        state: State,
        zip: zip,
        home_phone: HomePhone,
        cell_phone: CellPhone,
        residential_status: ResidentialStatus,
        time_at_adress: PreviousTime + "years" + timemonth + "months",
        rent_mtg_pmt: JSON.stringify(Rent),
        email: Email,
        drivers_license_no: Licenseno,
        drivers_license_state: LicenseState,
        time_at_previous_address:
          Previousyears + "years" + Previousmonth + "months",
        previous_address: PreviousAddress,
        previous_apt_suit: PreviousAptno,
        previous_po_box: PreviousPobox,
        previous_rural_route: PreviousRuralroute,
        previous_city: PreviousCity,
        previous_state: PreviousState,
        previous_zip: Previouszip,
        employer_name: Employername,
        employment_type: Employertype,
        salary: Salary,
        salary_type: Salarytype,
        occupation: Occupation,
        employment_length:
          Employmentyears + "years" + Employmentmonths + "months",
        work_phone_number: WorkPhone,
        previous_employer_name: PreviousEmployername,
        previous_employment_type: PreviousEmployertype,
        previous_salary: "",
        previous_salary_type: "",
        previous_occupation: PreviousOccupation,
        previous_employment_length:
          PreviousEmploymentyears +
          "years" +
          PreviousEmploymentmonths +
          "months",
        previous_work_phone_number: PreviousWorkPhone,
        other_monthly_income: OtherIncome,
        source_other_income: SourceOtherIncome,
        comments: "",
      });

      var config = {
        method: "post",
        url: "https://mailer-service-dot-fund-leads-dev.et.r.appspot.com/finance/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          settranssuccess(true);
          setOpen(true);
          // if (response.status === 200) {
          //   window.location.href = "/consulting";
          // }
        })
        .catch(function (error) {
          console.log(error);
          settranserror(true);
        });
    } else {
      setemptyerror(true);
      setTimeout(() => {
        setemptyerror(false);
      }, 5000);
    }
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ color: "rgb(211, 177, 82)", fontWeight: "bold" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Do you want free consultaion?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Whether you’re purchasing a car for the first time or the thousandth
            time, we are here to help. Our competitive rates, refinancing, and
            loan protection help you get the most out of your money.Our Staff
            all have automobile Dealership and finance background! We know the
            ins-and-outs of the industry. Negotiate better, save time, with JTM
            Consulting.
          </Typography>
          <Typography></Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              marginTop: 30,
            }}
          >
            <Link to="/consulting">
              <button className="ready_to_pay">Continue to Consulting</button>
            </Link>
            <button className="cancel_btn_st" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      <section className="blog-out mb">
        <Back name="CarLoan" title="Appy For a Car Loan" cover={img} />
        <div style={{ marginTop: 25 }}>
          <p className="head_loan">
            Don’t be fooled by high-interest dealership loans.
            <span style={{ color: "rgb(211, 177, 82)" }}>
              JTM Consulting
            </span>{" "}
            can save you big with our New and Used Car Loans. With competitive
            rates, flexible terms deferred payments, and a speedy approval
            process, you’ll be on your new ride in no time.
          </p>
        </div>
        <div className="container recent main_container_form">
          <div className="carloan_form">
            <h1 className="fill_p">Please Fill the Form</h1>
            <hr
              style={{
                backgroundColor: "rgb(211, 177, 82)",
                border: "1px solid rgb(211, 177, 82)",
              }}
            />
            {/* Row 1------------------------------------------ */}
            <div className="input1_parent">
              <div>
                <label className="label_1">First Name :</label>
                <br />
                <input
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                  placeholder="First Name"
                  className="input1_childst2"
                ></input>
                {empty && Firstname.length <= 0 ? (
                  <label className="error_check_emty">
                    First Name Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label className="label_1">Last Name :</label>
                <br />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="input1_childst2"
                  onChange={(e) => setLastname(e.target.value)}
                ></input>
                {empty && Lastname.length <= 0 ? (
                  <label className="error_check_emty">
                    Last Name Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Middle Initial :</label>
                <br />
                <input
                  type="text"
                  placeholder="Middle Initial"
                  className="input1_childst2"
                  onChange={(e) => setMiddlename(e.target.value)}
                ></input>
                {empty && Middlename.length <= 0 ? (
                  <label className="error_check_emty">
                    Middle Name Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 2----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Social Security Number :</label>
                <br />
                <input
                  type="number"
                  placeholder="Social Security Number"
                  className="input1_child"
                  onChange={(e) => setSocialSecurityno(e.target.value)}
                ></input>
                {empty && SocialSecurityno.length <= 0 ? (
                  <label className="error_check_emty">
                    Social Security Number Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Birth Date :</label>
                <br />
                <input
                  type="date"
                  placeholder="Birth Date"
                  className="input1_child"
                  onChange={(e) => setDatebirth(e.target.value)}
                ></input>
                {empty && Datebirth.length <= 0 ? (
                  <label className="error_check_emty">
                    Date Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Address :</label>
                <br />
                <input
                  type="text"
                  placeholder="Address"
                  className="input1_child"
                  onChange={(e) => setAddress(e.target.value)}
                ></input>
                {empty && Address.length <= 0 ? (
                  <label className="error_check_emty">
                    Address Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 3----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Apt# / Suite#:</label>
                <br />
                <input
                  type="number"
                  placeholder="Enter Number Here"
                  className="input1_child"
                  onChange={(e) => setAptno(e.target.value)}
                ></input>
                {empty && Aptno.length <= 0 ? (
                  <label className="error_check_emty">
                    Apt# Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">P.O Box :</label>
                <br />
                <input
                  type="text"
                  placeholder="P.O Box"
                  className="input1_child"
                  onChange={(e) => setPoBox(e.target.value)}
                ></input>
                {empty && PoBox.length <= 0 ? (
                  <label className="error_check_emty">
                    P.O Box Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Rural Route</label>
                <br />
                <input
                  type="text"
                  placeholder="Rural Route"
                  className="input1_child"
                  onChange={(e) => setRuralroute(e.target.value)}
                ></input>
                {empty && Ruralroute.length <= 0 ? (
                  <label className="error_check_emty">
                    Rural Route Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 5----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">City :</label>
                <br />
                <input
                  type="text"
                  placeholder="City"
                  className="input1_child"
                  onChange={(e) => setCity(e.target.value)}
                ></input>
                {empty && City.length <= 0 ? (
                  <label className="error_check_emty">
                    City Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">State :</label>
                <br />
                <input
                  type="text"
                  placeholder="State"
                  className="input1_child"
                  onChange={(e) => setState(e.target.value)}
                ></input>
                {empty && State.length <= 0 ? (
                  <label className="error_check_emty">
                    State Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Zip :</label>
                <br />
                <input
                  type="number"
                  placeholder="Zip"
                  className="input1_child"
                  onChange={(e) => setZip(e.target.value)}
                ></input>
                {empty && zip.length <= 0 ? (
                  <label className="error_check_emty">Zip Can,t be Empty</label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 6----------------------------------- */}
            <div className="input1_parent">
              <div className="time_main_st">
                <label className="label_1">Time at Address :</label>
                <br />
                <input
                  type="number"
                  className="time_st"
                  onChange={(e) => setPreviousTime(e.target.value)}
                ></input>
                Yrs.
                <input
                  type="number"
                  className="time_st"
                  onChange={(e) => setTimemonth(e.target.value)}
                ></input>
                Mos.
                {empty && timemonth.length <= 0 ? (
                  <label className="error_check_emty">
                    Time at Address Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Cell Phone :</label>
                <br />
                <input
                  type="number"
                  placeholder="Cell Phone"
                  className="input1_child"
                  onChange={(e) => setCellPhone(e.target.value)}
                ></input>
                {empty && CellPhone.length <= 0 ? (
                  <label className="error_check_emty">
                    Cell Phone Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Residential Status(Current) :</label>
                <br />
                <div className="radio_st">
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Homeowner"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Homeowner&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Rent"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Rent&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Family"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Family&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="resident"
                    id="same"
                    value="Other"
                    onChange={(e) => setResidentialStatus(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Other&nbsp;&nbsp;&nbsp;
                </div>
                {empty && ResidentialStatus.length <= 0 ? (
                  <label className="error_check_emty">
                    Residential Status Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Previous Address-------------------------- */}
            {!showPreviousTime ? (
              ""
            ) : (
              <div>
                {" "}
                {/* Row 1----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">Previous Full Address :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Address"
                      className="input1_child"
                      onChange={(e) => setPreviousAddress(e.target.value)}
                    ></input>
                    {empty && PreviousAddress.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous Address Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label className="label_1">Previous Apt# / Suite#:</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Enter Number Here"
                      className="input1_child"
                      onChange={(e) => setPreviousAptno(e.target.value)}
                    ></input>
                    {empty && PreviousAptno.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous Apt# Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label className="label_1">P.O Box :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="P.O Box"
                      className="input1_child"
                      onChange={(e) => setPreviousPobox(e.target.value)}
                    ></input>
                    {empty && PreviousPobox.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous P.O Box Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* Row 2----------------------------------- */}
                <div className="input1_parent">
                 
                  <div>
                    <label className="label_1">Rural Route</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Rural Route"
                      className="input1_child"
                      onChange={(e) => setPreviousRuralroute(e.target.value)}
                    ></input>
                    {empty && PreviousRuralroute.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous Rural Route Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label className="label_1">City :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="City"
                      className="input1_child"
                      onChange={(e) => setPreviousCity(e.target.value)}
                    ></input>
                    {empty && PreviousCity.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous City Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label className="label_1">State :</label>
                    <br />
                    <input
                      type="text"
                      placeholder="State"
                      className="input1_child"
                      onChange={(e) => setPreviousState(e.target.value)}
                    ></input>
                    {empty && PreviousState.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous State Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* Row 3----------------------------------- */}
                {/* Row 4----------------------------------- */}
                <div className="input1_parent">
                  <div>
                    <label className="label_1">Zip :</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Zip"
                      className="input1_child"
                      onChange={(e) => setPreviouszip(e.target.value)}
                    ></input>
                    {empty && Previouszip.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous Zip Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="time_main_st">
                    <label className="label_1">
                      Time at Previous Address :
                    </label>
                    <br />
                    <input
                      type="number"
                      className="time_st"
                      onChange={(e) => setPreviousyears(e.target.value)}
                    ></input>
                    Yrs.
                    <input
                      type="number"
                      className="time_st"
                      onChange={(e) => setPreviousmonths(e.target.value)}
                    ></input>
                    Mos.
                    {empty && Previousyears.length <= 0 ? (
                      <label className="error_check_emty">
                        Previous Time Can,t be Empty
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                <label className="label_1">Home Phone :</label>
                <br />
                <input
                  type="tel"
                  placeholder="Home Phone"
                  className="input1_child"
                  onChange={(e) => setHomePhone(e.target.value)}
                ></input>
                {empty && HomePhone.length <= 0 ? (
                  <label className="error_check_emty">
                    Home Phone Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
                </div>
              </div>
            )}
            {/* Row 7----------------------------------- */}
            <div className="input1_parent"></div>
            {/* Row 6----------------------------------- */}
            <div className="input1_parent">
             
              <div>
                <label className="label_1">Rent/Mtg. Pmt $ :</label>
                <br />
                <input
                  type="number"
                  placeholder="Rent/Mtg $"
                  className="input1_child"
                  onChange={(e) => setRent(e.target.value)}
                ></input>
                {empty && Rent.length <= 0 ? (
                  <label className="error_check_emty">
                    Rent Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">E-Mail Address :</label>
                <br />
                <input
                  type="email"
                  placeholder="E-Mail "
                  className="input1_child"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                {empty && Email.length <= 0 ? (
                  <label className="error_check_emty">
                    Email Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Driver's License No :</label>
                <br />
                <input
                  type="number"
                  placeholder="License No"
                  className="input1_child"
                  onChange={(e) => setLicenseno(e.target.value)}
                ></input>
              </div>
            </div>
            {/* Row 8----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Driver's License State :</label>
                <br />
                <input
                  type="text"
                  placeholder="License State"
                  className="input1_child"
                  onChange={(e) => setLicenseState(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Employer Name :</label>
                <br />
                <input
                  type="text"
                  placeholder="Employer Name"
                  className="input1_child"
                  onChange={(e) => setEmployername(e.target.value)}
                ></input>
                {empty && Employername.length <= 0 ? (
                  <label className="error_check_emty">
                    Employer Name Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 10----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Employment type :</label>
                <br />
                <div className="radio_stt">
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Employed"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Employed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="UnEmployed"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;UnEmployed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Self-Employed"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Self-Employed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Military"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Military&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Retired"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Retired&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Student"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Student&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Other"
                    onChange={(e) => setEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Other&nbsp;&nbsp;&nbsp;
                </div>
                {empty && Employertype.length <= 0 ? (
                  <label className="error_check_emty">
                    Employer Type Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 9----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Salary $ :</label>
                <br />
                <input
                  type="number"
                  placeholder="Salary in $"
                  className="input1_child"
                  onChange={(e) => setSalary(e.target.value)}
                ></input>
                {empty && Salary.length <= 0 ? (
                  <label className="error_check_emty">
                    Salary Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Salary type :</label>
                <br />
                <div className="radio_st">
                  <input
                    type="radio"
                    name="salarytype"
                    value="Weekly"
                    onChange={(e) => setSalarytype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Weekly&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="salarytype"
                    value="Bi-Weekly"
                    onChange={(e) => setSalarytype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Bi-Weekly&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="salarytype"
                    value="Monthly"
                    onChange={(e) => setSalarytype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Monthly&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="salarytype"
                    value="Annulay"
                    onChange={(e) => setSalarytype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Annulay&nbsp;&nbsp;&nbsp;
                </div>
                {empty && Salarytype.length <= 0 ? (
                  <label className="error_check_emty">
                    Salary Type Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Occupation :</label>
                <br />
                <input
                  type="text"
                  placeholder="Occupation"
                  className="input1_child"
                  onChange={(e) => setOccupation(e.target.value)}
                ></input>
                {empty && Occupation.length <= 0 ? (
                  <label className="error_check_emty">
                    Occupation Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 10----------------------------------- */}
            <div className="input1_parent">
              
              <div className="time_main_st">
                <label className="label_1">Length Of Employment :</label>
                <br />
                <input
                  type="number"
                  className="time_st"
                  onChange={(e) => setEmploymentyears(e.target.value)}
                ></input>
                Yrs.
                <input
                  type="number"
                  className="time_st"
                  onChange={(e) => setEmploymentmonths(e.target.value)}
                ></input>
                Mos.
                {empty && Employmentyears.length <= 0 ? (
                  <label className="error_check_emty">
                    Length Of Employment Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Work Phone Number :</label>
                <br />
                <input
                  type="number"
                  placeholder="Work Phone Number"
                  className="input1_child"
                  onChange={(e) => setWorkPhone(e.target.value)}
                ></input>
                {empty && WorkPhone.length <= 0 ? (
                  <label className="error_check_emty">
                    Work Phone Number Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Previous Employer Name :</label>
                <br />
                <input
                  type="text"
                  placeholder="Previous Employer Name"
                  className="input1_child"
                  onChange={(e) => setPreviousEmployername(e.target.value)}
                ></input>
                {empty && PreviousEmployername.length <= 0 ? (
                  <label className="error_check_emty">
                    Previous Employer Name Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Previous Employer history---------------- */}
            {/* Row 1----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Previous Employment type :</label>
                <br />
                <div className="radio_stt">
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Employed"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Employed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="UnEmployed"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;UnEmployed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Self-Employed"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Self-Employed&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Military"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Military&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Retired"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Retired&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Student"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Student&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="Employment"
                    id="same"
                    value="Other"
                    onChange={(e) => setPreviousEmployertype(e.target.value)}
                  ></input>
                  &nbsp;&nbsp;&nbsp;Other&nbsp;&nbsp;&nbsp;
                </div>
                {empty && PreviousEmployertype.length <= 0 ? (
                  <label className="error_check_emty">
                    Previous Employer Type Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 2----------------------------------- */}
            <div className="input1_parent">
              <div>
                <label className="label_1">Previous Occupation :</label>
                <br />
                <input
                  type="text"
                  placeholder="Previous Occupation"
                  className="input1_child"
                  onChange={(e) => setPreviousOccupation(e.target.value)}
                ></input>
                {empty && PreviousOccupation.length <= 0 ? (
                  <label className="error_check_emty">
                    Previous Occupation Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label className="label_1">Previous Work Phone Number :</label>
                <br />
                <input
                  type="number"
                  placeholder="Previous Work Phone"
                  className="input1_child"
                  onChange={(e) => setPreviousWorkPhone(e.target.value)}
                ></input>
                {empty && PreviousWorkPhone.length <= 0 ? (
                  <label className="error_check_emty">
                    Previous Work Phone Number Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="time_main_st">
                <label className="label_1">
                  Length Of Previous Employment :
                </label>
                <br />
                <input
                  type="number"
                  className="time_stprev"
                  onChange={(e) => setPreviousEmploymentyears(e.target.value)}
                ></input>
                Yrs.
                <input
                  type="number"
                  className="time_stprev"
                  onChange={(e) => setPreviousEmploymentmonths(e.target.value)}
                ></input>
                Mos.
                {empty && PreviousEmploymentyears.length <= 0 ? (
                  <label className="error_check_emty">
                    Previous Length of Employment Can,t be Empty
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Row 12----------------------------------- */}
            <div className="input1_parent">
             
              <div>
                <label className="label_1">Other Income (if any) :</label>
                <br />
                <input
                  type="number"
                  placeholder="(Optional)"
                  className="input1_child"
                  onChange={(e) => setOtherIncome(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="label_1">Source of other Income :</label>
                <br />
                <input
                  type="text"
                  placeholder="(Optional)"
                  className="input1_child"
                  onChange={(e) => setSourceOtherIncome(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="input1_parent">
              <div>
                <label className="label_1">
                  By Signing, you certify that the income entered on this Credit
                  Form is correct :
                </label>
                <br />
                {/* Signature Pad */}
                <div>
                  <SignaturePadClass />
                </div>
              </div>
            </div>
            <hr
              style={{
                backgroundColor: "rgb(211, 177, 82)",
                border: "1px solid rgb(211, 177, 82)",
              }}
            />
            <div className="submit_container">
              <button onClick={CraloanHandle} className="submit_child_st">
                Submit Form
              </button>
            </div>
            {emptyerror === true ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Alert
                  style={{
                    width: 350,
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  Please fill all the required fields :
                </Alert>
              </div>
            ) : (
              ""
            )}
            {!transsuccess ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <Alert
                  style={{ width: 350 }}
                  variant="outlined"
                  severity="success"
                >
                  Details Submitted Successfully!
                </Alert>
              </div>
            )}
            {!transerror ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <Alert
                  style={{ width: 350 }}
                  variant="outlined"
                  severity="error"
                >
                  Error Emissions Occurs!
                </Alert>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CarLoan;
