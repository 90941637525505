import React from "react";
import "./video.css";
const Video = () => {
  return (
    <>
      <div className="video_main">
        <div className="video_child">
          <div className="video_child_child">
            <h2 className="h2__tag">
              You will glad you made the right decision by consulting,
              <br /> with JTM Consulting before making the largest purchase
            </h2>
            <p  style={{ width: "100%", marginTop: 20 }}>
              Whether you're purchasing a car for the first time or the
              thousandth time, we are here to help. Our competitive network of
              banks, refinancing, and loan protection help you get the most out
              of your money, and time!
            </p>
          </div>
          <div>
            <iframe
            className="video_st_ch"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/TeYUoCAewuw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
