import { Link } from "react-router-dom";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "./style.css";
const steps = [
  {
    id: "Greet",
    message:
      "Hi! how are you i am Finance my car Assistant Do you want a car to enjoy your luxury Life?",
    trigger: "value",
  },
  {
    id: "value",
    user: true,
    trigger: "solution",
  },
  {
    id: "solution",
    options: [
      {
        value: 1,
        label: (
          <Link style={{ color: "white" }} to="/qualifyform">
            Pre-Qualify For a Loan
          </Link>
        ),
      },
      {
        value: 2,
        label: (
          <Link style={{ color: "white" }} to="/luxurycars">
            Luxury Cars
          </Link>
        ),
      },
      // {
      //   value: 3,
      //   label: (
      //     <Link style={{ color: "white" }} to="/warrantypurchase">
      //       Warranty
      //     </Link>
      //   ),
      // },
    ],
    end: true,
  },
];

// Creating our own theme
const theme = {
  background: "#E0C25D",
  headerBgColor: "#000000",
  headerFontSize: "20px",
  botBubbleColor: "#000000",
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#000000",
  userFontColor: "white",
};

// Set some properties of the bot
const config = {
  //   botAvatar: "img.png",
  floating: true,
};

const Chatbot = () => {
  return (
    <div className="Appsec">
      <ThemeProvider theme={theme}>
        <ChatBot
          // This appears as the header
          // text for the chat bot
          headerTitle="Finance Assistant"
          steps={steps}
          {...config}
        />
      </ThemeProvider>
    </div>
  );
};

export default Chatbot;
