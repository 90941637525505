import React from "react";
import Back from "../../common/Back";
import img from "../../images/blog3.jpeg";
import img2 from "../../images/blogimg.jpeg";
import img3 from "../../images/blogimg1.jpeg";
import img4 from "../../images/blogimg2.jpeg";
import img5 from "../../images/blogimg3.jpeg";
import img6 from "../../images/car3.jpeg";
import "../blog1/blog1.css";
const ThirdBlog = () => {
  return (
    <>
      <section className="blog-out mb">
        <Back name="Blog" title="Blog Grid - Our Blogs" cover={img} />

        <div className="main_blog_comtainer1">
          <h1
            style={{
              margin: "0px 0px 25px 0px",
              fontWeight: 600,
              fontSize: 25,
            }}
          >
            Why is APR so much higher than interest rate?
          </h1>
          <div className="img_parent">
            <img className="img_selection_blog1" src={img} alt=""></img>
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                margin: "0px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              New Car Prices Are Up to $7,200 Over Last Year: Here’s Why
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              There are four factors that are driving up the prices of new cars,
              trucks, and SUVs in 2022.
            </p>
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              The average new-car price in late 2019, prior to the COVID-19
              pandemic, was about $35, 000. By the finish of 2020, that price
              hit $38, 000. And by late 2021, it ballooned to a record-breaking
              $45, 000, according to J. D. Power.
            </p>
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              That works out to a jump of around 18 % in only a year, which J.
              D. Power’s Tyson Jominy, vice president of data and analytics,
              attributes to a quartet of factors: more dealer profits, a trend
              toward costlier trim levels, limited rebates, and continued
              interest in pricier trucks and SUVs.
            </p>
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              Those factors add up to an increase of about $7, 200 per vehicle
              in 2022. Here’s the skinny on how car prices have fattened up over
              the last year.
            </p>
            <br />
            <img
              style={{ width: "70%", marginTop: 30 }}
              className="img_selection_blog1"
              src={img4}
              alt=""
            ></img>
            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              UNDERSTANDING THE INTEREST RATE ON AN AUTO LOAN
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              An automobile loan’s interest rate is the total annual cost to be
              lent money to buy the car, portrayed as a percent. For those who
              have a good credit score, you may be eligible for a low-interest
              rate. When your credit report isn’t nearly as good, you might pay
              an increased interest rate.
              <br />
              <br />
              On the other hand, interest isn’t the only cost could pay when
              loaning a car purchased. You might also pay fees — and that’s
              where APR comes in.
            </p>
            <div className="img_parent">
              <img
                style={{ width: "100%", marginTop: 30 }}
                className="img_selection_blog1"
                src={img5}
                alt=""
              ></img>
            </div>
            <h1
              style={{
                margin: "30px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              HOW TO USE APR TO COMPARE AUTO LOAN OFFERS
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              When you compare auto loan offers to look for the best offer,
              concentrate on the total annual percentage rate (APR), the
              interest rate, and the size of the loan—and not merely the
              month-to-month payment. If you don’t obtain the best deal
              available to you on the interest rate, you can conclusion up
              paying considerable additional interest amounts over the life of
              the loan.
            </p>
            <br />
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              The size of the loan term is also important in thinking about the
              overall cost of the money. The longer loan phrase may mean smaller
              monthly payments, but you will probably pay more in interest over
              the life of the loan. A person should consider carefully whether
              you want to lengthen the money term just to get your
              month-to-month payments down. Producing your loan phrase longer
              may well not be worth the extra costs and you could be remaining
              with a vehicle that’s worth less than your expenses. Therefore
              don’t concentrate on the monthly payment by yourself. The big
              picture is the total cost of the money. The total cost of your
              vehicle loan will depend on the expense of credit (interest rate
              and APR), which is reflected in your monthly payment, and how many
              weeks you will need to make that payment per month (the loan
              term). In other words, how much you will pay overall depends
              partially on how long you will have to pay.
            </p>
            <h1
              style={{
                margin: "30px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              WHAT IS THE DIFFERENCE BETWEEN INTEREST RATE AND APR?
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Given that APR includes both interest rates plus certain costs
              connected with a mortgage loan, APR can assist you to understand
              the complete cost of a mortgage if you maintain it for the
              complete term. The APR will certainly usually be greater than the
              interest price, but there are usually exceptions.
            </p>
            <br />
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              The first is the no-closing-cost refinance: Within this case, the
              particular interest rate plus APR would be the exact same.
            </p>
            <br />
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              Another is definitely an adjustable-rate mortgage (ARM). The
              particular APR for a good ARM will at times be lower as compared
              to the interest level. This can take place within a declining
              curiosity rate environment any time lenders can believe in their
              advertising and marketing that your curiosity rate will probably
              be reduced when it resets than when an individual gets the
              financial loan.
            </p>
            <br />
            <br />
            <img
              style={{ width: "70%", marginTop: 30 }}
              className="img_selection_blog1"
              src={img6}
              alt=""
            ></img>
             <br />
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              However, this about an adjustable-rate mortgage loan is simply a
              calculation, because no person could predict what may happen to
              the percentage of interest over your financial loan term. Your
              INTEREST in a PROVIDE is only going to be knowable after you’ve
              paid out the money.
            </p>
            <br />
            <br />

            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              Conclusion
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              I have noticed that there are many things in the world that can be
              changed after COVID-19. The prices of everything, trends, social
              interaction, online work, local-market, and many other goods.
              COVID-19 is the turning point of each point in the world. Some
              businesses fall down and some businesses grow up in COVID-19 like
              Mask sales is increased, online working is also increasing,
              Increase Usage of social media, and also in cars business. Trucks
              are in high demand bcz they use for delivery purposes. People do
              not go out from home. That’s why simple cars are not used more.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirdBlog;
