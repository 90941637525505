import { React, useState } from "react";
import "./footer.css";
import FooterImg from "../../images/jtmlogo.png";
import { Link } from "react-router-dom";
import axios from "axios";
const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const SubmitConsulting = () => {
    var data = JSON.stringify({
      first_name: name,
      email: email,
      cell_phone: phone,
    });

    var config = {
      method: "post",
      url: "https://mailer-service-dot-fund-leads-dev.et.r.appspot.com/finance/freeConsulting",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <div className="send flex">
            <div className="text">
              <h1 className="text_footer">Do You Have Questions ?</h1>
              <p>We'll help you to grow your career and growth.</p>
            </div>
            <Link to="/contact">
              <button className="btn5">Contact Us</button>
            </Link>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <img src={FooterImg} alt="" />
              <h2 style={{ fontSize: 20 }}>Do You Need Help With Anything?</h2>
              <p>
                Receive updates, hot deals, tutorials, discounts sent straignt
                in your inbox every month
              </p>
            </div>
            <div style={{ marginTop: 50 }}>
              <a href="https://www.facebook.com/JTMConsulting/" target="blank">
                <i
                  style={{ color: "rgb(211, 177, 82)" }}
                  class="fa-brands fa-2x fa-facebook-f"
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/in/joshua-t-m-8762a612"
                target="blank"
              >
                {" "}
                <i
                  style={{ color: "rgb(211, 177, 82)", marginLeft: 20 }}
                  class="fa-brands fa-2x  fa-linkedin"
                ></i>
              </a>
              <a
                href="https://www.instagram.com/jtmconsulting1/"
                target="blank"
              >
                {" "}
                <i
                  style={{ color: "rgb(211, 177, 82)", marginLeft: 20 }}
                  class="fa-brands fa-2x  fa-instagram"
                ></i>
              </a>
            </div>
          </div>
          {/* 
          <div className="box">
            <h3 style={{ fontSize: 16 }}>Layout</h3>
            <ul className="hoverz_st">
              <Link to="/">
                <li> Home Page</li>
              </Link>
              <Link to="/about">
                <li> About Page</li>
              </Link>
              <Link to="/services">
                <li> Services</li>
              </Link>
              <Link to="/refinancemycar">
                <li>Sell your Car</li>
              </Link>
              <Link to="/contact">
                <li>Contact</li>
              </Link>
            </ul>
          </div> */}
          {/* <div className="box">
            <h3 style={{ fontSize: 16 }}>Legal</h3>
            <ul className="hoverz_st">
              <Link to="/termsofuse">
                <li> Terms of use</li>
              </Link>
              <Link to="/privacypolicy">
                <li> Privacy Policy</li>
              </Link>
            </ul>
          </div> */}
          {/* <div id="consult" className="box">
            <h3 style={{ fontSize: 14 }}>FREE CONSULTATION</h3>
            <ul>
              <li>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Name"
                ></input>
              </li>
              <li>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                ></input>
              </li>
              <li>
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                  placeholder="Phone"
                ></input>
              </li>
              <li>
                <button
                  onClick={SubmitConsulting}
                  style={{ backgroundColor: "rgb(211, 177, 82)" }}
                >
                  Submit
                </button>
              </li>
            </ul>
          </div> */}
        </div>
      </footer>
      <div className="legal">
        <span>
          Copyright © 2022{" "}
          <span style={{ color: "rgb(211, 177, 82)" }}>Matutech LLC</span>.
        </span>
      </div>
    </>
  );
};

export default Footer;
