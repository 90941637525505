import { React, useState } from "react";
import Heading from "../../../components/common/Heading";
import "./main.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "./Animation/button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  marginTop: 10,
  p: 4,
};
const ButtonMain = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <section className="location padding">
        <div className="container">
          <Heading
            title="Our services"
            subtitle="JTM Consulting has been saving thousands of clients thousands of dollars in negotiations and most importantly, time!"
          />
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
            onClick={handleOpen}
          >
            <Button />
          </div>
        </div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <b>
                A $48 fee will be applied to process Pre-qualification
                application.
              </b>
              <br />
              <br /> The processing will give you access to your consultation
              about your credit report and eligibility for auto loan type.
              Application fees are used to cover screening costs incurred to
              process applications properly.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 20,
                marginTop: 30,
                overflowY: "scroll",
              }}
            >
              <Link to="/qualifyform">
                <button className="ready_to_pay">Continue</button>
              </Link>
              <button className="cancel_btn_st" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default ButtonMain;
