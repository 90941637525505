import React from "react";
import Back from "../../common/Back";
import img from "../../images/blog2.jpeg";
import img2 from "../../images/blogimg.jpeg";
import img3 from "../../images/blogimg1.jpeg";
import "../blog1/blog1.css";
const SecondBlog = () => {
  return (
    <>
      <section className="blog-out mb">
        <Back name="Blog" title="Blog Grid - Our Blogs" cover={img} />

        <div className="main_blog_comtainer1">
          <h1
            style={{
              margin: "0px 0px 25px 0px",
              fontWeight: 600,
              fontSize: 25,
            }}
          >
            What You Should Know About Dealer Finance
          </h1>
          <div className="img_parent">
            <img className="img_selection_blog1" src={img} alt=""></img>
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                margin: "0px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              What makes Car Prices Shooting upwards in the Usa States of The
              united states?
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Fresh and used automobile prices in typically the USA are
              attaining record levels in addition to showing no indications of
              softening. While consumer appetite for automobiles continues to
              increase, tight inventories in addition to tangled supply
              restaurants are so that it is challenging for auto firms to keep
              upward with the requirement.
            </p>
            <h1
              style={{
                margin: "0px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              Grasp the Craigslist – Used Car Getting Tips
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Searching for a car and buying used? May look further until you
              arm yourself with our used car buying tips. Information is the
              most complete out there and will demonstrate the complete process
              from finding the car to inspecting the car and sealing the deal.
            </p>
            <div className="img_parent">
              <img
                style={{ width: "100%", marginTop: 30 }}
                className="img_selection_blog1"
                src={img2}
                alt=""
              ></img>
            </div>
            <h1
              style={{
                margin: "30px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              Guide to Car Rental Companies in Costa Rica 2015
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Explained Car Rental Firms in Costa Natural – Rate Reviews for
              2015: Car rental inside Costa Rica is usually a possibility to
              check out the remote shorelines and quiet sides of this stunningly
              beautiful country. Traveling without the constraints set by open
              public transport timetables in addition to with freedom above
              group tours. Regarding the unprepared traveler; however, car local
              rental costs and providers can be quite a rude jolt and also a
              blow to be able to that vacation price range.
            </p>
            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              Buying Used Cars? A new Definitive Guide to Buy a Superb Used Car
              Without Overpaying
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              With a big selection of used cars nowadays, there is no greater
              value than buying a used car. However, it is also the highest
              risk, particularly if you have no idea about what you should do to
              get the best deal without getting scammed by unscrupulous people
              who are ready to cheat you. Therefore, it is highly recommended
              that you arm yourself with all the needed research and collect the
              most possible information about the specific used car you wish to
              buy.
            </p>
            <h1
              style={{
                margin: "30px 0px 25px -10px",
                fontWeight: 600,
                fontSize: 25,
              }}
            >
              Car Finance – What You Should Know About Dealer Finance
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Car lease has become a huge business. A large number of brand new
              and used vehicle buyers in the united kingdom are usually making
              their automobile purchase on finance of some type, and several
              folks opt to arrange it via the dealership. Therefore what does a
              person need to learn about financing your vehicle from your
              dealer?
            </p>
            <img
              style={{ width: "50%", marginTop: 30 }}
              className="img_selection_blog1"
              src={img3}
              alt=""
            ></img>
            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              Downsizing Your Car In addition to What you need to Take into
              accounts
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              “I’m downsizing my car” is a term that car sellers hear a great
              deal from buyers. Individuals often get to some stage where they
              may be considering a smaller-sized car than these people currently
              have, for numerous sorts reasons. Often it can be empty-nesters
              who no more need to get the kids to varsity, footy practice, going
              swimming lessons, camping vacations and so upon. Other times it
              can be for reasons associated with the economy – “my current car
              expenses me a lot to run” will be a phrase generally used in
              combination with downsizing. Yet there are a few essential things
              in order to consider when downsizing your car.
            </p>
            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              Electric powered and Hybrid Automobiles – The Trend of The
              Upcoming
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              It looks like we’ve been waiting around forever for electric cars
              to come along, but after more false begins than you’ll see at the
              London Olympics this year, it looks like the electric car is
              finally here to stay. But is an electric car something you need to
              be considering for your next car?
            </p>
            <h1
              style={{
                margin: "30px 0px 25px 0px",
                fontWeight: 600,
                fontSize: 25,
                width: "70%",
              }}
            >
              Conclusion
            </h1>
            <p style={{ fontSize: 19, width: "70%" }}>
              Cross cars are definitely more environmentally friendly than
              internal-combustion vehicles. Batteries are now being designed to
              have longevity. Batteries are being engineered to have a long
              life. Once the cross cars be a little more common, battery
              recycling will become economically possible.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondBlog;
