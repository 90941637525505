import React from "react";
import Back from "../common/Back";
import PriceCard from "../home/price/PriceCard";
import img from "../images/pricing.jpg";
import "../home/price/price.css";
import "./price.css";
const Pricing = () => {
  return (
    <>
      <section className="pricing mb">
        <Back
          name="JTM Consulting"
          title="Consultation programs and costs"
          cover={img}
        />
        <div className="price container">
          <p style={{ marginTop: 50, fontSize: 17 }}>
            Whether you’re purchasing a car for the first time or the thousandth
            time, we are here to help. Our competitive rates, refinancing, and
            loan protection help you get the most out of your money.Our Staff
            all have automobile Dealership and finance background! We know the
            ins-and-outs of the industry. Negotiate better, save time, with JTM
            Consulting.
          </p>
          <div
            className="st_free_ref"
            style={{ marginTop: 50, padding: "10px 0px 0px 0px" }}
          >
            <h2 style={{ marginTop: 20, color: "rgb(211, 177, 82)" }}>
              FREE REFERRAL
            </h2>
            <p style={{ marginTop: 20 }}>
              Free referral to a network of trusted car dealerships. No haggle
              and hassle free!
            </p>
            <a href="#consult">
              <button
                style={{ marginTop: 20, backgroundColor: "rgb(211, 177, 82)" }}
              >
                Book Now
              </button>
            </a>
          </div>
          <PriceCard />
        </div>
      </section>
    </>
  );
};

export default Pricing;
