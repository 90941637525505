import { React } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img2 from "../images/pexels-photo-5046305.webp";
import Negotiate from "../home/Negoshiate/negotiate";
import { Carousel } from "react-carousel3";
import Car1 from "../images/WhatsApp Image 2023-06-09 at 8.13.59 PM.jpeg";
import Car2 from "../images/WhatsApp Image 2023-06-09 at 8.14.27 PM.jpeg";
import Car3 from "../images/WhatsApp Image 2023-06-09 at 8.14.42 PM.jpeg";
import Car4 from "../images/jeepki.jpeg";
import Car5 from "../images/WhatsApp Image 2023-06-09 at 8.16.46 PM (1).jpeg";
import Car8 from "../images/WhatsApp Image 2023-06-09 at 8.16.48 PM.jpeg";
import "./luxury.css";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
const settings = {
  Infinite: true,
  speed: 100,
  slidesshow: 1,
  slidesToscroll: 1,
  autoplay: true,
  adaptiveHeight: true,
};
const style = {
  width: 320,
  height: 320,
};

const SimpleSlider = () => {
  // const history = useNavigate();

  const Submit1 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ez0q4x9",
        "template_al0px9n",
        e.target,
        "AL4ZgOott-0hvog1g"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Submit2 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_od19beh",
        "template_e4afpyr",
        e.target,
        "lg3uEj83KtNEIrhFF"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Submit3 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_xu3zo5b",
        "template_1ysudhi",
        e.target,
        "lg3uEj83KtNEIrhFF"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <ToastContainer />

      <div className="main_corousel ">
        <Slider {...settings}>
          <div className="stfd">
            <img className="image_corousel" src={Img2} alt="Image1"></img>
            <p className="ptag" style={{ width: "60%" }}>
              GET THE HIGHEST AND BEST OFFER FAST! <br />
              Get YOUR EXOTIC OR HIGH-END LUXURY VEHICLE TODAY!
            </p>
            <div className="contextapi">
              <ToastContainer />

              <form className="consult_child1" onSubmit={Submit1}>
                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="text"
                    placeholder="First Name"
                    className="input_consult2"
                    name="fname"
                  ></input>
                </div>
                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="text"
                    placeholder="Last Name"
                    className="input_consult2"
                    name="lname"
                  ></input>
                </div>
                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="text"
                    placeholder="Car Name"
                    className="input_consult2"
                    name="carname"
                  ></input>
                </div>
                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="text"
                    placeholder="Model"
                    className="input_consult2"
                    name="car_model"
                  ></input>
                </div>

                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="email"
                    placeholder="Email Address"
                    className="input_consult2"
                    name="user_email"
                  ></input>
                </div>
                <div>
                  <input
                    style={{ color: "black", backgroundColor: "white" }}
                    type="tel"
                    placeholder="Phone Number"
                    className="input_consult2"
                    name="phone"
                  ></input>
                </div>

                <div>
                  <button type="submit" className="submit__consult">
                    Submit Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Slider>
      </div>
      <div className="consulting_main2" style={{ marginTop: 20 }}>
        <div className="consult_child2">
          <h3 className="font_st_free2">Just choose your favourite One :)</h3>
          <div className="st_st_st">
            <p style={{ fontSize: 17 }}>
              We are dealing with every type of Car.So, be relax and choose your
              best one.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: "linear-gradient(to bottom, #16235e 0%, #020223 100%)",
          marginTop: 0,
          overflowX: "hidden",
        }}
      >
        <Carousel
          height={460}
          width={980}
          yOrigin={42}
          yRadius={48}
          autoPlay={true}
        >
          <div key={1} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car1} />
          </div>
          <div key={2} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car2} />
          </div>
          <div key={3} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car3} />
          </div>
          <div key={4} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car4} />
          </div>
          <div key={5} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car5} />
          </div>

          <div key={6} style={style}>
            <img style={{ borderRadius: 16 }} alt="" src={Car8} />
          </div>
        </Carousel>
      </div>
      <div className="consulting_main2" style={{ marginTop: 19 }}>
        <div className="consult_child2">
          <div className="st_st_st">
            <p style={{ fontSize: 19 }}>
              Hurry up! don't waste your time just fill out these form if you
              want to buy or sell your car and we will contact you directly.
              <br />
              <br />
              Remember, we sell cars, but if you want to sell, we can help you
              through sell “consignment“
            </p>
          </div>
        </div>
      </div>
      <div className="flexbox3">
        <ToastContainer />

        <form className="consulting_main1" onSubmit={Submit2}>
          <div className="consult_child1">
            <h3 className="font_st_free">Which Car you want to buy?</h3>
            <div>
              <input
                type="text"
                placeholder="Full Name"
                className="input_consult2"
                name="fname"
              ></input>
            </div>
            <div>
              <input
                type="text"
                placeholder="Car Name"
                className="input_consult2"
                name="carname"
              ></input>
            </div>
            <div>
              <input
                type="text"
                placeholder="Car Model"
                className="input_consult2"
                name="car_model"
              ></input>
            </div>

            <div>
              <input
                type="email"
                placeholder="Email"
                className="input_consult2"
                name="user_email"
              ></input>
            </div>
            <div>
              <input
                type="tel"
                placeholder="Phone Number"
                className="input_consult2"
                name="phone"
              ></input>
            </div>
            <textarea
              type="text"
              placeholder="Describe in detail"
              className="input_consult24"
              name="descrip"
            ></textarea>
            <div>
              <button type="submit" className="submit__consult">
                Submit Request
              </button>
            </div>
          </div>
        </form>
        <form className="consulting_main1" onSubmit={Submit3}>
          <div className="consult_child1">
            <h3 className="font_st_free">Are you selling your car?</h3>
            <div>
              <input
                type="text"
                placeholder="Full Name"
                className="input_consult2"
                name="fname"
              ></input>
            </div>
            <div>
              <input
                type="text"
                placeholder="Car Name"
                className="input_consult2"
                name="carname"
              ></input>
            </div>
            <div>
              <input
                type="text"
                placeholder="Car Model"
                className="input_consult2"
                name="car_model"
              ></input>
            </div>
            <div>
              <input
                type="number"
                placeholder="How many miles"
                className="input_consult2"
                name="miles"
              ></input>
            </div>
            <div>
              <input
                type="text"
                placeholder="Vin"
                className="input_consult2"
                name="vin"
              ></input>
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                className="input_consult2"
                name="user_email"
              ></input>
            </div>
            <div>
              <input
                type="number"
                placeholder="Phone Number"
                className="input_consult2"
                name="phone"
              ></input>
            </div>
            <div>
              <textarea
                type="text"
                placeholder="Describe in detail"
                className="input_consult24"
                name="phone"
              ></textarea>
            </div>
            <div>
              <button type="submit" className="submit__consult">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div style={{ marginTop: 20 }}>
        <Negotiate />
      </div>
    </div>
  );
};
export default SimpleSlider;
