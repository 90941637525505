import React, { useState, useEffect } from "react";
// import Awards from "./awards/Awards";
// import Featured from "./featured/Featured";
// import Hero from "./hero/Hero";
// import Location from "./location/Location";
import Team from "./team/Team";
import SliderComponent from "./corousel/corousel";
// import HomeBlog from "./HomeBlog/bloghome";
import Testominals from "./Testominals/testominal";
import Video from "./Video/video";
import Negotiate from "./Negoshiate/negotiate";
import ThirdComponent from "./Animation/animation";
import ButtonMain from "./ServicesButton/MainButton";
import Header from "../common/header/Header";
// import Modal from "@mui/material/Modal";
// import { Link } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "50%",
//   bgcolor: "background.paper",
//   border: "2px solid rgb(211, 177, 82)",
//   boxShadow: 24,
//   borderRadius: 3,
//   marginTop: 10,
//   p: 4,
// };
const Home = () => {
  // const [open, setOpen] = useState(false);
  // const handleClose = () => setOpen(false);
  // const handleOpen = () => setOpen(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleOpen();
  //   }, 5000);
  // }, []);
  return (
    <>
      <SliderComponent />
      {/* <Featured /> */}
      <ThirdComponent />
      {/* <Awards /> */}
      <ButtonMain />
      {/* <Team /> */}
      {/* <HomeBlog /> */}
      <Video />
      <Testominals />
      <Negotiate />

      {/* <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll", zIndex: 30000 }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>
              A $48 fee will be applied to process Pre-qualification
              application.
            </b>
            <br />
            <br /> The processing will give you access to your consultation
            about your credit report and eligibility for auto loan type.
            Application fees are used to cover screening costs incurred to
            process applications properly.
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              marginTop: 30,
              overflowY: "scroll",
            }}
          >
            <Link to="/applicationcheckout">
              <button className="ready_to_pay">Continue</button>
            </Link>
            <button className="cancel_btn_st" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <ButtonMain />
        </Box>
      </Modal> */}
    </>
  );
};

export default Home;
