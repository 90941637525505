import React, { useState } from "react";
import "./warranty.css";
import Img from "../images/warranty.webp";
import Img2 from "../images/brandwarranty.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  marginTop: 10,
};
const Warranty = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const SubmitConsulting = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ez0q4x9",
        "template_al0px9n",
        e.target,
        "AL4ZgOott-0hvog1g"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <ToastContainer />

      <div class="hero2">
        <navbar className="happyb">
          <img src={Img} alt="" class="logooo" />
          <button className="button8" onClick={handleOpen}>
            Protect Your Car
          </button>
        </navbar>
        <div class="content1">
          <h1>
            Protecting you like a member
            <br /> of our Royal family.
          </h1>
        </div>
      </div>
      <div>
        <img alt="" src={Img2}></img>
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={style}>
          {/* <Typography
            style={{
              color: "rgb(211, 177, 82)",
              fontWeight: "bold",
              marginBottom: 20,
              overflowY: "scroll",
              height: "auto",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Hurry Up! Don't Waste Your Time.
          </Typography> */}
          <div>
            <form className="consulting_main5" onSubmit={SubmitConsulting}>
              <div className="consult_child3">
                <h3 className="font_st_free">Customer Information</h3>
                <div className="dflex5">
                  <div>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="input_consult5"
                      name="fname"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="input_consult5"
                      name="lname"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Co First Name:"
                      className="input_consult5"
                      name="cofname"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Co Last Name:"
                      className="input_consult5"
                      name="colname"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Address:"
                      className="input_consult5"
                      name="address"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Apt#:"
                      className="input_consult5"
                      name="apt"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="City:"
                      className="input_consult5"
                      name="city"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="State:"
                      className="input_consult5"
                      name="state"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Zip Code:"
                      className="input_consult5"
                      name="zipcode"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      className="input_consult5"
                      name="user_email"
                    ></input>
                  </div>
                  <div>
                    <input
                      type="number"
                      placeholder="Phone Number"
                      className="input_consult5"
                      name="phone"
                    ></input>
                  </div>
                </div>
                <div>
                  <button type="submit" className="submit__consult">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              marginTop: 30,
              overflowY: "scroll",
            }}
          >
            {/* <Link to="/qualifyform">
              <button className="ready_to_pay">Yes i,m ready</button>
            </Link> */}
            <button className="cancel_btn_st" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Warranty;
