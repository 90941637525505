import { React } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import "./form.css";
import { useNavigate } from "react-router-dom";
const Form = () => {
  const history = useNavigate();
  const SubmitConsulting = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_w09bx04",
        "template_yukbjot",
        e.target,
        "AL4ZgOott-0hvog1g"
      )
      .then((res) => {
        console.log(res);
        toast("Successfully Submitted");
        setTimeout(() => {
          history.push("/thanksforapplying");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <ToastContainer />
      <h3
        style={{
          padding: 20,
          fontFamily: "sans-serif",
          fontWeight: "normal",
          fontSize: 17,
        }}
      >
        <p style={{ width: "80%" }}>
          {/* A $48 fee will be applied to process Pre-qualification application. */}
          The processing will give you access to your consultation about your
          credit report and eligibility for auto loan type. Application fees are
          used to cover screening costs incurred to process applications
          properly.
        </p>
      </h3>
      <form className="consulting_main1" onSubmit={SubmitConsulting}>
        <div className="consult_child1">
          <h3 className="font_st_free">Pre Qualify For a Loan</h3>
          <div>
            <input
              type="text"
              placeholder="Enter Name"
              className="input_consult2"
              name="name"
            ></input>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              className="input_consult2"
              name="user_email"
            ></input>
          </div>
          <div>
            <input
              type="number"
              placeholder="Phone Number"
              className="input_consult2"
              name="phone"
            ></input>
          </div>
          <div>
            <button type="submit" className="submit__consult">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
