import React from "react";
import Back from "../../common/Back";
import img from "../../images/blog1.jpeg";
import "./blog1.css";

const FirstBlog = () => {
  return (
    <>
      <section className="blog-out mb">
        <Back name="Blog" title="Blog Grid - Our Blogs" cover={img} />

        <div className="main_blog_comtainer1">
          <h1
            style={{
              margin: "0px 0px 25px 0px",
              fontWeight: 600,
              fontSize: 25,
            }}
          >
            Ridiculous Inflation Is In this article To Stay (What This Means
            Regarding Car Prices)
          </h1>
          <div className="img_parent">
            <img className="img_selection_blog1" src={img} alt=""></img>
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: 19, width: "70%" }}>
              Probably the most asked question in today’s market is – “WHEN are
              car prices going to go back to NORMAL?”
            </p>
            <br />
            <p style={{ fontSize: 19, width: "70%" }}>
              The particular answer, of course, is NEVER.
              <br />
              <br /> To explain why I’m heading to go over two basic ideas that
              individuals are missing. First off – we have inflation depending
              on demand and supply.
              <br />
              <br /> Let us look at the Lamborghini market for example. Let’s
              say you purchased a Gallardo Superleggera in
              <br />
              <br /> 2020 at 120k. Well, that identical car is now 180k. 2020’s
              300k Aventador is now worth around 450k.
              <br />
              <br /> How do We know that the price is not coming back? Due to
              this fact, something very simple happened.
              <br />
              <br /> Not just do they demand an increase, but money also got
              devalued.
              <br />
              <br /> I can give you a good example to easily understand: In case
              you own a home, that home grows up in value
              <br />
              <br /> (everybody understands that). But what you’re missing away
              is that your home is not the only one that
              <br />
              <br />
              develops. EVERYBODY’S home grows up in value. That means everyone
              in your neighborhood got more
              <br />
              <br /> potent at the same rate And because of that: Everybody’s
              buying energy went UP. This means all the
              <br />
              <br />
              “luxury goods” prices these individuals could afford proceeded to
              go up to change for their new internet
              <br />
              <br /> worth. Everything occurred is – a new baseline was formed.
              120k then is the equivalent of 180k now.
              <br />
              <br /> Therefore now you can see why the prices won’t return to
              where they were pre-covid. The 2nd thing that
              <br />
              <br /> individuals don’t understand in economics like this… Would
              be that the wealth space gets even BIGGER
              <br />
              <br /> because of one thing: CONTINGENCY. <br />
              <br />
            </p>
            <p style={{ fontSize: 19, width: "70%" }}>
              Making use of Real Estate is an example… <br />
              <br />
              When you purchase a 500k home, you rely on the financial
              institution to finance that home. <br />
              <br />
              Whenever someone buys a 3M house, they rely on MONEY. Therefore
              the contingency is the financial <br />
              <br /> institution. The element to understand here is that the fed
              comes in and regulates the people that depend <br />
              <br /> on a financial institution to finance their home. <br />
              <br /> Basically
              raising the rates of interest to make it MORE DIFFICULT to buy to
              slow down the market. <br />
              <br /> The Given doesn’t care if you take the rich
              side of the equation because you pay cash anyway. You may <br />
              <br /> feel
              that this is unfair. Why would they restrict NORMAL PEOPLE’S
              ability to buy but not rich people? <br />
              <br /> Due to the fact, rich people
              are buying with THEIR OWN money, their RISK… <br />
              <br /> When a normal person
              is using the bank’s money (aka the financial institution is
              risking here). The given <br />
              <br /> actually regulates the banks to ensure
              you’re not revealing the financial institution to some substantial
              risk. <br />
              <br /> This particular is the gist of what is happening in the
              whole car industry. Notice that hypercars and <br />
              <br /> collector vehicles
              above $300k where people buy in CASH have not been influenced by
              interest rates, as <br />
              <br /> the cars that individuals rely on credit to buy
              have begun to soften. It is the very same thing. Because a car <br />
              <br />
              hacker, there is a huge opportunity in buying basically today that
              the money buyers will want tomorrow. <br />
              <br /> V8-10-12, manual, rare,
              valuable exotics. Individuals who buy those vehicles today will
              love huge profits <br />
              <br /> within the next 12-24 months.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstBlog;
