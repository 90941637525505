import "./App.css";
// import Pages from "./components/pages/Pages";
import { BrowserRouter, useLocation, Route, Routes } from "react-router-dom";
import { React, useLayoutEffect } from "react";
import Chatbot from "./components/Chat/Chatbot";
import Home from "./components/home/Home";
import Footer from "./components/common/footer/Footer";
import About from "./components/about/About";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import CarLoan from "./components/Car Loan/carloan";
import RefinanceMyCar from "./components/SellYourCar/refinancemycar";
import FirstBlog from "./components/blog/blog1/blog1";
import SecondBlog from "./components/blog/blog2/blog2";
import ThirdBlog from "./components/blog/blog3/blog3";
import Payment from "./components/PaymentMain/paymentmain";
import TermsOfUse from "./components/Termsofuse/termsofuse";
import PrivacyPolicy from "./components/PrivacyPolicy/privacypolicy";
import PaymentBespoke from "./components/PaymentMain/paymentmain2";
import FreePayment from "./components/PaymentMain/freeconsultpayment";
import Luxury from "./components/Luxury/Luxury.js";
import Formm from "./components/Forms/Form";
import Warranty from "./components/Warranty/Warranty";
import Thankyou from "./components/Thankyou";
import Header from "./components/common/header/Header";
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <BrowserRouter>
      <Header />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/consulting" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/applyforaloan" element={<CarLoan />} />
          <Route path="/refinancemycar" element={<RefinanceMyCar />} />
          <Route path="/blog1" element={<FirstBlog />} />
          <Route path="/blog2" element={<SecondBlog />} />
          <Route path="/blog3" element={<ThirdBlog />} />
          <Route path="/checkout" element={<Payment />} />
          <Route path="/checkout2" element={<PaymentBespoke />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/applicationcheckout" element={<FreePayment />} />
          <Route path="/luxurycars" element={<Luxury />} />
          <Route path="/qualifyform" element={<Formm />} />
          <Route path="/warrantypurchase" element={<Warranty />} />
          <Route path="/thanksforapplying" element={<Thankyou />} />
        </Routes>
        <Chatbot />
        <Footer />
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
