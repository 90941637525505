import React from "react";
import Back from "../common/Back";
import img from "../images/pricing.jpg";
import "./privacypolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <section className="pricing mb">
        <Back name="JTM Consulting" title="Privacy Policy" cover={img} />
        <div className="main_container_terms">
          <span>updated 10/28/2022</span>
          <p style={{ marginTop: 50, fontWeight: 500 }}>INTRODUCTION:</p>
          <p style={{ marginTop: 50 }}>
            This Privacy Policy is designed to assist you in understanding how
            we collect, use and safeguard the information you provide to us in
            using our websites (the “Sites”) and the services provided through
            our Sites (the “Services”).
          </p>
          <p style={{ marginTop: 50 }}>
            From time to time, we may change this Privacy Policy. If we do, we
            will post an amended version on this webpage. Please review this
            Privacy Policy periodically. Your continued use of the Sites and
            Services after any such amendment signifies your acceptance thereof.
          </p>
          <p style={{ marginTop: 50, fontWeight: 500 }}>
            COLLECTING AND USING INFORMATION:
          </p>
          <p style={{ marginTop: 50, fontWeight: 600 }}>
            Personally Identifiable Information We Collect Online;
          </p>
          <p style={{ marginTop: 50 }}>
            Through your use of the Sites and Services, we may collect
            Personally Identifiable Information from you. “Personally
            Identifiable Information” is individually identifiable information
            about an individual consumer that we collect online and that we
            maintain in an accessible form.
          </p>
          <h3 style={{ marginTop: 30, fontWeight: "normal", padding: 30 }}>
            Information You Provide :
          </h3>
          <p style={{ marginTop: 30, fontWeight: "normal" }}>
            We may collect the following Personally Identifiable Information
            that you voluntarily provide to us:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              Apply Now. When you apply to refinance your auto loan, you will
              provide us with Personally Identifiable Information such as your
              first and last name, telephone number, email address, and physical
              address. You also will provide us with information relating to
              your vehicle (such as its year, make, model, trim, and estimated
              number of miles), information relating to your current loan (such
              as the name of your current auto lender and current monthly auto
              payment), information relating to your current residence (such as
              the type of residence and your monthly payment), information
              regarding your employment and income, and your date of birth. You
              also may voluntarily agree to provide us with your Social Security
              number. If you respond to an offer in the mail to refinance your
              auto loan, you will provide your pre-qualification code and zip
              code.
            </li>
            <li>
              Prequalification. When you explore your options with our “Get
              Prequalified” you will provide us with Personally Identifiable
              Information such as your first and last name and physical address.
              You also will provide us with other information such as your date
              of birth, yearly income, monthly vehicle payments, and vehicle
              year.
            </li>
            <li>
              Contact Us. If you contact us through the Sites, we may collect
              your name, email address, and telephone number along with any
              other Personally Identifiable Information you voluntarily provide.
            </li>
            <li>
              Interact with our Sites or Services. When you send us any
              feedback, questions, comments, suggestions, ideas, or interact
              with us in any way, you may need to provide us with your log-in
              information, name and/or email address.
            </li>
          </ol>
          <h3 style={{ marginTop: 30, fontWeight: "normal", padding: 30 }}>
            Information as You Navigate Our Sites:
          </h3>
          <p style={{ marginTop: 30, fontWeight: "normal" }}>
            We automatically collect certain Personally Identifiable Information
            through your use of the Sites and Services, such as the following:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              Usage Information. We collect information such as which of the
              pages on the Sites you access, the frequency of access, and what
              you click on while on the Sites.
            </li>
            <li>
              Prequalification. When you explore your options with our “Get
              Prequalified” you will provide us with Personally Identifiable
              Information such as your first and last name and physical address.
              You also will provide us with other information such as your date
              of birth, yearly income, monthly vehicle payments, and vehicle
              year.
            </li>
            <li>
              Device Information. We collect information about the device you
              are using, such as hardware model, operating system, application
              version number, and browser.
            </li>
            <li>
              Mobile Device Information. In addition to the Device Information
              listed above, when you access our Sites via a browser on your
              mobile device, we collect and aggregate information about whether
              you are accessing the Sites via a mobile device or tablet, device
              type, and carrier.
            </li>
            <li>
              Location Information. We collect location information from Site
              visitors on a city-regional basis.
            </li>
          </ol>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>Third Party Information:</span>
            <br />
            In some cases, we may receive certain Personally Identifiable
            Information from you about a third party. For example, if you choose
            to use the “Remind Me To Apply Later Feature” on our Careers page,
            you may provide us with the email address of a third-party. If you
            submit any Personally Identifiable Information about another
            individual to us, you are responsible for making sure that you have
            the authority to do so and to allow us to use their Personally
            Identifiable Information in accordance with this Privacy Policy.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              Information from Other Sources
            </span>
            <br />
            When you apply for a loan, we may obtain Personally Identifiable
            Information about you from other sources, such as information about
            your credit profile or other information from Experian, TransUnion,
            and Equifax. We also may collect information about you from other
            sources to verify the information you provided in your loan
            application.
          </p>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              How We Use Your Personally Identifiable Information
            </span>
            <br />
            We use the Personally Identifiable Information we collect to provide
            the Services to you, to improve our Services and Sites, and to
            protect our legal rights. In addition, we may use the Personally
            Identifiable Information we collect to:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>Find you loan options from our lender base;</li>
            <li>Review your job application;</li>
            <li>
              Send you notifications of our latest news and updates if you
              voluntarily elect to receive them;
            </li>
            <li>
              Communicate with you via telephone, mobile devices, email or
              postal mail about our products and services that we feel may be of
              interest to you;
            </li>
            <li>
              Research and analyze other products and services offered by us or
              others that may save you money;
            </li>
            <li>
              Deliver relevant advertising and analyze the effectiveness of our
              advertising and other campaigns;
            </li>
            <li>
              Communicate with you about our Sites or Services or to inform you
              of any changes to our Sites or Services;
            </li>
            <li>Provide support;</li>
            <li>Maintain and improve our Sites and Services;</li>
            <li>Defend our legal rights and the rights of others;</li>
            <li>Efficiently maintain our business; and</li>
            <li>Comply with applicable law.</li>
          </ol>
          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              How We Share Your Personally Identifiable Information
            </span>
            <br />
            We may share the Personally Identifiable Information that we collect
            about you in the following ways:
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>
              Us and our Affiliates. We may share your Personally Identifiable
              Information with subsidiaries and affiliates. These subsidiaries
              and affiliates adhere to this Privacy Policy.
            </li>
            <li>
              Lenders. Once you have submitted an application, we will begin the
              process of finding you a loan, if qualified, from our lender base
              of over 200 financial institutions. Your Personally Identifiable
              Information is not sent to each lender in our network for review.
              Instead, your credit profile and needs are matched to the borrower
              profile established by our lenders and sent to those lenders
              first. By submitting an application, you consent to the use of
              your credit report for the purpose of obtaining a credit offer
              from one of our participating lenders.
            </li>
            <li>
              To Comply with the Law. We may disclose Personally Identifiable
              Information we collect if we determine in our sole discretion that
              such disclosure is necessary to comply with the law, including
              disclosure to law enforcement agencies and other government
              agencies and disclosure responsive to subpoenas. We also may
              disclose information we collect if we deem it necessary to protect
              the rights, property, or safety of us, our customers or others; in
              connection with any legal proceedings or prospective legal
              proceedings; and to any person who we reasonably believe may apply
              to a court or other competent authority for disclosure of that
              Personally Identifiable Information where, in our reasonable
              opinion, such court or authority would be reasonably likely to
              order disclosure of that Personally Identifiable Information.
            </li>
            <li>
              To Third Parties. We may disclose your Personally Identifiable
              Information to third party vendors that provide us services to
              process your information for purposes of reviewing your loan
              application. We may disclose the information we collect to
              companies that perform marketing services on our behalf, with
              which we have joint marketing agreements, or with which we have
              other agreements that, among other things, require the
              safeguarding of your information.
            </li>
            <li>
              Advertising. We may disclose your Personally Identifiable
              Information to service providers to prepare, deploy, and analyze
              advertising content
            </li>
            <li>
              Sale of Assets. We may disclose your Personally Identifiable
              Information to any other person or entity as part of any business
              or asset sale.
            </li>
            <li>
              Consent. We may disclose your Personally Identifiable Information
              to any other person or entity where you consent to the disclosure.
            </li>
          </ol>

          <p style={{ marginTop: 30 }}>
            <span style={{ fontWeight: "bold" }}>
              COOKIES AND OTHER TRACKING TECHNOLOGIES:
            </span>
            <br />
            <br />
            Like many other companies, we use cookies and other tracking
            technologies (such as pixels and web beacons) (collectively,
            “Cookies”). “Cookies” are small files of information that are stored
            by your web browser software on your computer hard drive, mobile or
            other devices (e.g., smartphones or tablets).
          </p>
          <ol
            style={{
              marginTop: 30,
              fontWeight: "normal",
              padding: "30px 0px 0px 50px",
            }}
          >
            <li>Estimate audience size and usage patterns;</li>
            <li>
              Understand and save your preferences for future visits, allowing
              us to customize the Sites and Services to your individual needs;
            </li>
            <li>
              Advertise new content and services that relate to your interests;
            </li>
            <li>Keep track of advertisements and search engine results;</li>
            <li>
              Compile aggregate data about site traffic and site interactions to
              resolve issues and offer better site experiences and tools in the
              future; and
            </li>
            <li>
              Recognize when you return to the Sites and identify the
              originating source from which you came to the Sites.
            </li>
          </ol>

          <p style={{ marginTop: 30 }}>
            We set some Cookies ourselves and others are set by other entities.
            We use Cookies set by other entities to provide us with useful
            information to help us improve our Site and Services, to conduct
            advertising, and to analyze the effectiveness of advertising. For
            example, we use Cookies from Google, Facebook and other similar
            companies.
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
