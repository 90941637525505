import { React, useState } from "react";
import "./payment.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
var qs = require("qs");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgb(211, 177, 82)",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};
const FreePayment = () => {
  const [transsuccess, settranssuccess] = useState(false);
  const [carderror, setcarderror] = useState(true);
  const [open, setOpen] = useState(false);
  const [openconfirm, setOpenConfirm] = useState(false);
  const [movetoloan, setmovetoLoan] = useState(false);
  const [paymentid, setpaymentid] = useState("");
  const [confirmid, setConfirmtid] = useState("");
  const handleClose = () => setOpen(false);
  const handleClosePay = () => setOpenConfirm(false);
  const handleMovetoloan = () => setmovetoLoan(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [cardnumber, setCardnumber] = useState("");
  const [expmonth, setexpMonth] = useState("");
  const [expyear, setexpYear] = useState("");
  const [cvv, setCVV] = useState("");

  // API STRIPE (Payment Method)--------------
  const PaymentHandler = () => {
    var data = qs.stringify({
      type: "card",
      "billing_details[address][city]": city,
      "billing_details[address][country]": "US",
      "billing_details[address][line1]": address,
      "billing_details[address][postal_code]": zip,
      "billing_details[address][state]": state,
      "billing_details[email]": email,
      "billing_details[name]": name,
      "card[exp_month]": expmonth,
      "card[exp_year]": expyear,
      "card[number]": cardnumber,
      "card[cvc]": cvv,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com//v1/payment_methods",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51LwggxExK3n0PPqt7iyjWH61aMhrzsTaJ4CFaFgrEwjQmvVIPfCKKjObKDmCw5t9QdxEgacPP7kVI2lQFBABpa1a009nQrnyjJ",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.id);
        setpaymentid(response.data.id);
        setOpen(true);
        setcarderror(true);
      })
      .catch(function (error) {
        console.log(error);
        setcarderror(false);
      });
  };
  // PaymentIntentHandler API-------------------
  const PaymentIntentHandler = () => {
    var data = qs.stringify({
      amount: "4800",
      currency: "usd",
      payment_method: paymentid,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com//v1/payment_intents",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51LwggxExK3n0PPqt7iyjWH61aMhrzsTaJ4CFaFgrEwjQmvVIPfCKKjObKDmCw5t9QdxEgacPP7kVI2lQFBABpa1a009nQrnyjJ",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.id);
        setConfirmtid(response.data.id);
        setOpen(false);
        setOpenConfirm(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // Payment Confirm API---------
  const PaymentConfirm = () => {
    var data = qs.stringify({});
    var config = {
      method: "post",
      url: `https://api.stripe.com//v1/payment_intents/${confirmid}/confirm`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51LwggxExK3n0PPqt7iyjWH61aMhrzsTaJ4CFaFgrEwjQmvVIPfCKKjObKDmCw5t9QdxEgacPP7kVI2lQFBABpa1a009nQrnyjJ",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenConfirm(false);
        settranssuccess(true);
        setmovetoLoan(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <h3
        style={{
          padding: 20,
          fontFamily: "sans-serif",
          fontWeight: "normal",
          fontSize: 17,
        }}
      >
        1. $48 Fee is for processing (processing fee) the application for
        Pre-approval and doing credit analysis.
        <br />
        <br />
        2. We can not use "Car Loan" yet, unfortunately. Soon we will. We are a
        3rd party.
        <br />
        <br />
        <p style={{ width: "80%" }}>
          A $48 fee will be applied to process Pre-qualification application.
          The processing will give you access to your consultation about your
          credit report and eligibility for auto loan type. Application fees are
          used to cover screening costs incurred to process applications
          properly.
        </p>
      </h3>
      <div class="row">
        <div class="col-75">
          <div class="containerstt">
            <div class="row">
              <div class="col-50">
                <h3 style={{ marginBottom: 30 }}>Billing Address</h3>
                <label for="fname">
                  <i class="fa fa-user"></i> Full Name
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="John M. Doe"
                />
                <label for="email">
                  <i class="fa fa-envelope"></i> Email
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="john@example.com"
                />
                <label for="adr">
                  <i class="fa fa-address-card-o"></i> Address
                </label>
                <input
                  onChange={(e) => setAddress(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="adr"
                  name="address"
                  placeholder="542 W. 15th Street"
                />
                <label for="city">
                  <i class="fa fa-institution"></i> City
                </label>
                <input
                  onChange={(e) => setCity(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="New York"
                />

                <div class="row">
                  <div class="col-50">
                    <label for="state">State</label>
                    <input
                      onChange={(e) => setState(e.target.value)}
                      className="input_checkout_st"
                      type="text"
                      id="state"
                      name="state"
                      placeholder="NY"
                    />
                  </div>
                  <div class="col-50">
                    <label for="zip">Zip</label>
                    <input
                      onChange={(e) => setZip(e.target.value)}
                      className="input_checkout_st"
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="10001"
                    />
                  </div>
                </div>
              </div>

              <div class="col-50">
                <h3>Payment</h3>
                <label for="fname">Accepted Cards</label>
                <div class="icon-container">
                  <i class="fa fa-cc-visa" style={{ color: "navy" }}></i>
                  <i class="fa fa-cc-amex" style={{ color: "blue" }}></i>
                  <i class="fa fa-cc-mastercard" style={{ color: "red" }}></i>
                  <i class="fa fa-cc-discover" style={{ color: "orange" }}></i>
                </div>
                <label for="ccnum">Credit card number</label>
                <input
                  onChange={(e) => setCardnumber(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="ccnum"
                  name="cardnumber"
                  placeholder="1111-2222-3333-4444"
                />
                <label for="expmonth">Exp Month</label>
                <input
                  onChange={(e) => setexpMonth(e.target.value)}
                  className="input_checkout_st"
                  type="text"
                  id="expmonth"
                  name="expmonth"
                  placeholder="September"
                />
                <div class="row">
                  <div class="col-50">
                    <label for="expyear">Exp Year</label>
                    <input
                      onChange={(e) => setexpYear(e.target.value)}
                      className="input_checkout_st"
                      type="text"
                      id="expyear"
                      name="expyear"
                      placeholder="2018"
                    />
                  </div>
                  <div class="col-50">
                    <label for="cvv">CVV</label>
                    <input
                      onChange={(e) => setCVV(e.target.value)}
                      className="input_checkout_st"
                      type="text"
                      id="cvv"
                      name="cvv"
                      placeholder="352"
                    />
                  </div>
                </div>
              </div>
            </div>
            {!transsuccess ? (
              <input
                onClick={PaymentHandler}
                className="input_checkout_st"
                type="submit"
                value="Continue to checkout"
                class="btn"
              />
            ) : (
              <Alert variant="outlined" severity="success">
                Transection Completed Successfully!
              </Alert>
            )}
            {carderror === false ? (
              <Alert severity="error">Error Card Details Are Not Correct</Alert>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="col-25">
          <div class="container st_contaoiner_check">
            <h4>
              Cart{" "}
              <span class="price" style={{ color: "black" }}>
                <i class="fa fa-shopping-cart"></i> <b>1</b>
              </span>
            </h4>
            {/* <p>
              Free Consultaion<strike class="price">399$</strike>
            </p> */}
            <p style={{ fontSize: 14, marginTop: 10 }}>
              Application Charges<span class="price">48$</span>
            </p>
            <br />
            <hr />
            <p>
              Total{" "}
              <span class="price" style={{ color: "black" }}>
                <b>48$</b>
              </span>
            </p>
          </div>
        </div>
        {/* <!-- The Modal --> */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              style={{ color: "rgb(211, 177, 82)" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Confirmation:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Congratulations! You are only one step away for buying Gold
              Package
            </Typography>
            <Typography>
              <div
                style={{ marginTop: 20 }}
                class="container st_contaoiner_check"
              >
                <h4>
                  Cart{" "}
                  <span class="price" style={{ color: "black" }}>
                    <i class="fa fa-shopping-cart"></i> <b>1</b>
                  </span>
                </h4>
                {/* <p>
                  Free Consultaion<strike class="price">399$</strike>
                </p> */}
                <p>
                  Application Charges<span class="price">48$</span>
                </p>

                <hr />
                <p>
                  Total{" "}
                  <span class="price" style={{ color: "black" }}>
                    <b>48$</b>
                  </span>
                </p>
              </div>
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 20,
                marginTop: 30,
              }}
            >
              <button className="ready_to_pay" onClick={PaymentIntentHandler}>
                Yes i,m ready to pay
              </button>
              <button className="cancel_btn_st" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
        {/* Confirm Payment Model */}
        <Modal
          open={openconfirm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmation:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Confirm Your Transection! Just need one more Click
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 20,
                marginTop: 30,
              }}
            >
              <button className="ready_to_pay" onClick={PaymentConfirm}>
                Confirm Transection
              </button>
              <button className="cancel_btn_st" onClick={handleClosePay}>
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={movetoloan}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Hooray!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Congratulations! You are Successfully Now able to Apply For a Car
              Loan.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 20,
                marginTop: 30,
              }}
            >
              <Link to="/applyforaloan">
                <button className="ready_to_pay">Apply For a Car Loan</button>
              </Link>
              {/* <button className="cancel_btn_st" onClick={handleMovetoloan}>
                Cancel
              </button> */}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default FreePayment;
