import React from "react";
import Tick from "../../images/tick.png";
import Cross from "../../images/cross.png";
import { Link } from "react-router-dom";
const PriceCard = () => {
  return (
    <>
      <div className="content   flex mtop">
        {/* Price 1 */}
        <div className="box setheight_st shadow">
          <h3>Basic Consultation</h3>
          <strike style={{ fontSize: 25 }}>
            <span>$</span>
            399
          </strike>
          <h1>Free</h1>
          {/* <p>per user, per month</p> */}
          <ul>
            <li>
              <label
                style={{
                  background: "#27ae601f",
                  color: "#27ae60",
                }}
              >
                <img src={Tick} alt=""></img>
              </label>
              <p style={{ width: "70%", marginLeft: -10 }}>
                Walk through the purchasing process.
              </p>
            </li>
            <li>
              <label
                style={{
                  background: "#27ae601f",
                  color: "#27ae60",
                }}
              >
                <img src={Tick} alt=""></img>
              </label>
              <p style={{ width: "70%", marginLeft: -16 }}>
                Negotiating tactics on the purchase
              </p>
            </li>
            <li>
              <label
                style={{
                  background: "#27ae601f",
                  color: "#27ae60",
                }}
              >
                <img src={Tick} alt=""></img>
              </label>
              <p>Negotiating Trade-ins.</p>
            </li>
            <li>
              <label
                style={{
                  background: "#27ae601f",
                  color: "#27ae60",
                }}
              >
                <img src={Tick} alt=""></img>
              </label>
              <p>Negotiating Financing.</p>
            </li>
          </ul>
          <Link to="/qualifyform">
            <button
              className="btn6_st"
              style={{
                background: "#27ae60",
                color: "#fff",
                marginTop: 180,
              }}
            >
              Book Now
            </button>
          </Link>
        </div>
        {/* <div className="box  shadow">
          <div className="topbtn">
            <button className="btn3">Best Value</button>
          </div>
          <h3>Gold Package</h3>
          <h1>
            <span>$</span>
            1500
          </h1>
    
          <ul>
            <li>
              <label
                style={{
                  background: "#27ae601f",
                  color: "#27ae60",
                }}
              >
                <img src={Tick} alt=""></img>
              </label>
              <p>Includes all of Basic package</p>
            </li>
            <li>
              <p>
                Walk through the whole process, until you're satisfied and
                signed the paper work.
              </p>
            </li>
            <li>
              <label
                style={{
                  background: "#dc35451f",
                  color: "#dc3848",
                }}
              >
                <img src={Cross} alt=""></img>
              </label>
              <p>We help find cars to compare.</p>
            </li>
            <li>
              <label
                style={{
                  background: "#dc35451f",
                  color: "#dc3848",
                }}
              >
                <img src={Cross} alt=""></img>
              </label>
              <p>Negotiate price with dealers.</p>
            </li>
            <li>
              <label
                style={{
                  background: "#dc35451f",
                  color: "#dc3848",
                }}
              >
                <img src={Cross} alt=""></img>
              </label>
              <p style={{ width: "70%", marginLeft: -10 }}>
                Find and consult for best finance options.
              </p>
            </li>
            <li>
              <label
                style={{
                  background: "#dc35451f",
                  color: "#dc3848",
                }}
              >
                <img src={Cross} alt=""></img>
              </label>
              <p>Work-out delivery of car and</p>
            </li>
          </ul>
          <Link to="/checkout" target="">
          <button
            className="btn6_st"
            style={{
              background: "#27ae60",
              color: "#fff",
            }}
          >
            Book Now
          </button></Link>
        </div> */}

        {/* Price 3 */}
      </div>
    </>
  );
};

export default PriceCard;
